import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useForAuthorisation } from "./context";

export default function PrivateRoutes({ component: Component, ...rest }) {
  //MAKE SURE TO {DESTRUCTURE } LOGGEDINUSER!!!
  //loading comes from context file as props with the context hook. used because auth changed is asynchrounous, so in delay
  // after page refresh loggedinuser is signed out, therefore is loading allows user to stay signed in. we can also add a
  // component to render while page is loading so unauthenticated users dont get access as well to ui?
  //
  const { loading } = useForAuthorisation();
  const { loggedInUser } = useForAuthorisation();
  // console.log(loggedInUser);
  return (
    <Route
      {...rest}
      render={(props) => {
        //added recently
        return loading ? (
          <div className="spinner-border m-5 text-primary" role="status"></div>
        ) : loggedInUser || loading ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
              state: props && props.location && props.location.pathname,
            }}
          />
        );
      }}
    ></Route>
  );
}
