import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import HomePage from "./HomePage";
import "./styles.css";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
const UsageInfo = () => {
  const [stylingOnClickUsageInfo, setstylingOnClickUsageInfo] = useState();
  useEffect(() => {
    let stop = false;
    if (stop) return;
    setstylingOnClickUsageInfo("MediumBlue");
    return () => {
      stop = true;
    };
  }, []);
  const [loading, setLoading] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  useEffect(() => {
    const storage = getStorage();
    setLoading(true);
    var arr = [];
    function displayFile(path) {
      return getDownloadURL(ref(storage, path))
        .then(function (url) {
          arr.push(url);

          setImagesArray([...arr]);
        })
        .catch(function (error) {
          console.log(error);

          // Handle any errors
        });
    }

    listAll(ref(storage, `/DocumentationImages/`))
      .then((res) => {
        // console.log(res);
        res.items.forEach(function (folderRef) {
          if (!folderRef) return;
          setLoading(true);
          displayFile(folderRef);
          setLoading(false);
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
      })
      .catch((error) => {
        console.log(error);

        // Uh-oh, an error occurred!
      });

    setLoading(false);
  }, []);
  function DisplayImage(fileName) {
    return (
      imagesArray.length &&
      imagesArray.filter((el) => el.includes(fileName)).toString()
    );
  }
  //

  function LoadingCheck(el) {
    if (loading) {
      return (
        <span
          style={{ margin: 15 }}
          className="spinner-border text-primary"
          role="status"
        ></span>
      );
    } else return el;
  }
  //
  function DownloadCSV(el) {
    ///
    const storage = getStorage();
    getDownloadURL(ref(storage, "SampleCSV/Upload_CSV_To_FilingStack.csv"))
      .then((urlValue) => {
        // console.log(urlValue);
        const url = urlValue;

        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute(
          "download",
          "CSV " + new Date().toLocaleDateString() + ".csv"
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.log(error);
        // Handle any errors
      });
  }
  return (
    <div>
      <HomePage stylingOnClickUsageInfo={stylingOnClickUsageInfo} />
      <div
        style={{
          height: 200,
          marginBottom: 25,
          backgroundColor: "rgb(231, 252, 255)",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            paddingTop: 70,
            color: "black",
          }}
        >
          Application Documentation
        </h2>
      </div>
      {loading ? (
        <span
          style={{ margin: 10 }}
          className="spinner-border text-primary"
          role="status"
        ></span>
      ) : null}
      <div className="documentation" style={{ textAlign: "left" }}>
        <ul style={{ marginRight: 40 }}>
          <div>
            <h5
              style={{
                padding: 15,
                marginLeft: 20,
                textDecoration: "underline",
              }}
            >
              We view it as essential and necessary that you take our quick and
              easy tutorial. Click{" "}
              <Link target="_blank" rel="noreferrer" to="/Tutorial">
                here
              </Link>{" "}
              to start.
            </h5>
            <span
              style={{
                paddingLeft: 15,
                marginLeft: 20,
              }}
            >
              (To begin the tutorial, you'll need to log in, to record your
              progress and to receive a completion certificate.)
            </span>
          </div>

          <h5
            style={{
              padding: 15,
              textDecoration: "underline",
              marginLeft: -5,
              margin: 20,
            }}
          >
            Documentation
          </h5>
          <h5
            style={{
              padding: 15,
              textDecoration: "underline",
              marginLeft: -5,
              margin: 20,
            }}
          >
            Contents
          </h5>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",
              padding: 15,

              marginLeft: -5,

              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <a href="#1">
              {" "}
              <h4 style={{ textAlign: "left" }}>1. Signing up </h4>
            </a>
            <a href="#2">
              {" "}
              <h4 style={{ textAlign: "left" }}>2. Info page </h4>
            </a>
            <a href="#3">
              {" "}
              <h4 style={{ textAlign: "left" }}>3. Accounts page </h4>
            </a>
            <a href="#4">
              {" "}
              <h4 style={{ textAlign: "left" }}>4. Uploading CSV files </h4>
            </a>
            <a href="#5">
              {" "}
              <h4 style={{ textAlign: "left" }}>5. Adding a new year </h4>
            </a>
            <a href="#6">
              {" "}
              <h4 style={{ textAlign: "left" }}>6. Simultaneous edits </h4>
            </a>
            <a href="#7">
              {" "}
              <h4 style={{ textAlign: "left" }}>7. Pagination </h4>
            </a>
            <a href="#8">
              {" "}
              <h4 style={{ textAlign: "left" }}>8. Trial balance & Ledger </h4>
            </a>
            <a href="#invoiceID">
              <h4 style={{ textAlign: "left" }}>9. Invoices </h4>
            </a>
            <a href="#10">
              {" "}
              <h4 style={{ textAlign: "left" }}>10. Edit account names </h4>
            </a>
            <a href="#11">
              {" "}
              <h4 style={{ textAlign: "left" }}>11. SOCE </h4>
            </a>
            <a href="#12">
              {" "}
              <h4 style={{ textAlign: "left" }}>12. Journals </h4>
            </a>
            <a href="#13">
              {" "}
              <h4 style={{ textAlign: "left" }}>13. Vat calculation </h4>
            </a>
            <a href="#14">
              {" "}
              <h4 style={{ textAlign: "left" }}>14. Payslips </h4>
            </a>
            <a href="#15">
              {" "}
              <h4 style={{ textAlign: "left" }}>
                15. Financial statement generation{" "}
              </h4>
            </a>
            <a href="#16">
              {" "}
              <h4 style={{ textAlign: "left" }}>16. Icons </h4>
            </a>
            <a href="#17">
              {" "}
              <h4 style={{ textAlign: "left" }}>
                17. Payment information & increasing limits{" "}
              </h4>
            </a>
            <a href="#18">
              {" "}
              <h4 style={{ textAlign: "left" }}>18. Bank feeds</h4>
            </a>
            <a href="#19">
              {" "}
              <h4 style={{ textAlign: "left" }}>19. Quickstart guide</h4>
            </a>
            <a href="#20">
              {" "}
              <h4 style={{ textAlign: "left" }}>20. Tutorial videos</h4>
            </a>
            <a href="#21">
              {" "}
              <h4 style={{ textAlign: "left" }}>21. Advertising</h4>
            </a>
          </li>

          <li
            style={{
              //backgroundColor: "#E7F3F0 ",
              padding: 15,

              marginLeft: -5,

              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="1" style={{ textAlign: "left" }}>
              1. Signing up
            </h4>
            To use this application, clients must sign up for an account with
            FilingStack. Clients can also sign up with a Google account.
            Thereafter clients can login with their email and password on
            subsequent visits.<br></br> <br></br>Users can add additional users
            on their profiles, using the Add User option in the Navigation menu
            on the Info page. If users forget their password, click on Reset
            Password on the login page and an email will be sent with details on
            resetting the password.
          </li>

          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="2" style={{ textAlign: "left" }}>
              2. Info page
            </h4>
            After logging in, users are redirected to the Info page. This page
            has links to other pages which have various options available,
            including updating users details and updating bank account opening
            balances.<br></br> <br></br> Initially when a client signs up and
            logs in for the first time, the only company that would reflect on
            the Info page is a demo company that is added automatically for new
            clients. The demo company can be used to evaluate the FilingStack
            Accounting product during the free trial period.<br></br> <br></br>{" "}
            Clients can add additional companies/businesses via the Add Company
            link found on the Info page. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("addCompany.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            However prior to adding additional companies, a client must update
            the default limits on their FilingStack profile. This is done by
            clicking on the Payments link found on the Info page. Once
            redirected to the Payments page a client must enter the amount of
            usage they'd like to add, and make payment via Payfast. FilingStack
            offers a subscription based month to month recurring billing option,
            where billing takes place per month.<br></br> <br></br> Clients can
            cancel their subscription at any time, by clicking on the Cancel
            subscription button on the Payments page. Click{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://youtu.be/wBploRRT5uo"}
            >
              here
            </a>{" "}
            to watch a video explaining the process to add additional companies
            onto a FilingStack Accounting profile. After adding new companies,
            the new company can be selected by clicking on the companies name
            from the list of companies on the Info page.<br></br> <br></br> The
            client would then be redirected to the Accounts page. If no company
            is selected, all other pages will redirect to the Info page. The Add
            User page has options to add additional users onto a profile.{" "}
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("addUser.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> <br></br>
          </li>

          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="3" style={{ textAlign: "left" }}>
              3. Accounts page
            </h4>
            After adding a new company and selecting the company on the Info
            page, a client would be redirected to the Accounts page. The
            Accounts page is used for uploading bank statements, and allocating
            transactions.<br></br> <br></br> The first thing that should be done
            on the Accounts page after a new company is added, is to add a bank
            account for the new company. This is done by clicking 'Add Bank
            Account' on the Accounts page. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("AddBankAccount.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            After a bank account is added, a client can then upload the
            transactions for that bank account, and allocate each transaction to
            an account.<br></br> <br></br> Additional Account groups can be
            added via the Add button on the Account group column, on the
            Accounts page.
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("AddAccount.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            If an account needs to re-allocated, transactions can be batch
            edited to the account. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("editAll.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            FilingStack software would thereafter automatically generate a
            ledger, trial balance, and other financial reports.<br></br>{" "}
            <br></br> It is important that all transactions uploaded are within
            the financial year for the company selected. Eg; if the company
            selected has a financial year 1 March 2021 to 28 February 2022, all
            transactions uploaded must be within that period. Dates with red
            text indicate out of year transactions. To add different years for
            the same company, the 'Add Year' link on the Accounts page must be
            used. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("addYear.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> <br></br> The "Add Year" link adds additional years and
            transactions occurring within those years can then be added. The
            first row on the accounts page can be used to enter new transaction
            data, if there are few transactions. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("entryRow.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            After an account group is selected, the Save button appears on the
            first row. The account name and account group details must be
            entered, and a bank account must be selected for the entry to save.
            <br></br> <br></br> Click{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://youtu.be/ZLYIzV4JeV4"}
            >
              here
            </a>{" "}
            to watch a video on how to add transactions onto the Accounts page.
            <br></br>
            <br></br>
            If you're entering opening balances; <br></br>
            Opening balances can be posted on the Accounts page. To ensure that
            the current years income statement doesn't include prior year
            opening balances, enter all opening balances in a prior year.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="4" style={{ textAlign: "left" }}>
              4. Uploading CSV (Comma separated values - excel file) bank
              statement files
            </h4>
            The Choose File button is used to select a CSV file for upload, and
            the Upload file button is used to upload a selected CSV file and is
            enabled after a file is selected. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("UploadFile.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            Bank statements must be uploaded in the csv format. Below is an
            image of how the csv file columns should look. To save an Excel file
            as CSV, click 'Save as Comma delimited' when saving the file.{" "}
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("csvFile.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            Click{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://youtu.be/zQf7mQkCeUE"}
            >
              here
            </a>{" "}
            to watch a video on how to upload a CSV file onto FilingStack
            Accounting software. <br></br> <br></br> Clients can download their
            bank statements in the CSV format from their banking website/app. If
            a bank statement is not available in the CSV format, the statement
            can be downloaded in the PDF format and exported to excel using{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="./ExtractData"
              target="_blank"
              rel="noreferrer"
            >
              AI Extract
            </a>
            .<br></br> <br></br> This is available on the{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="./ExtractData"
              target="_blank"
              rel="noreferrer"
            >
              Convert PDF
            </a>{" "}
            page. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("convertPDF.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("convertToCSV.png")}
                style={{ margin: 15, border: "1px dotted" }}
              ></img>
            )}{" "}
            <br></br>
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.microsoft.com/en-us/microsoft-365/excel"}
            >
              Microsoft Excel,
            </a>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.google.com/sheets/about/"}
            >
              Google Sheets
            </a>{" "}
            or{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                "https://www.libreoffice.org/download/download-libreoffice/"
              }
            >
              LibreOffice
            </a>{" "}
            can be used to edit the CSV file, to upload onto Filingstack
            Accounting software.<br></br> <br></br>
            All csv statements for upload onto FilingStack must be formatted
            into 3 columns, with the first row having Date, Description, Amount
            as the column heading for the first 3 columns. The Date column must
            be formatted as YYYY/MM/DD (eg; 2023/01/28). The Description column
            must have the bank statement transaction description, and the Amount
            column must have the transaction Amount. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("csvFile.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            Click{" "}
            <span
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={DownloadCSV}
            >
              here
            </span>{" "}
            to download a sample CSV file that is supported for upload onto
            FilingStack Accounting software. The format reflected on the sample
            CSV must be followed when uploading a CSV file onto FilingStack
            accounting.<br></br> <br></br>All transaction amounts received into
            the bank account must be recorded as positive numbers, eg; 5000, and
            all payments recorded as negative numbers, eg; -5000. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("csvFile.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> <br></br>If assistance is required, contact us via the{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="./Support"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>{" "}
            page. <br></br> <br></br>Transactions can then be allocated. While
            there is an Account group option when importing a CSV file, it
            should only be used to import CSV Account groups exported from the
            Filingstack accounts page, as the Account groups must match the
            accounts stored on Filingstack. If incorrect account group data is
            added, the financial statements may not balance and inaccurate data
            may result.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            <h4 id="5" style={{ textAlign: "left" }}>
              5. Adding a new year
            </h4>
            The "Add Year" option on the Accounts page must be used to add a new
            year for the company/business added. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("addYear.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            Other options on the Account page include Account Group which is
            used to allocate transactions. There are also options for Vat Type.
            <br></br> <br></br> The Vat column is only open for editing when
            manual vat is selected. The Link column has options to create a rule
            that tells the software to allocate all future transactions uploaded
            that have the same name, to the same account. It also has options to
            reconcile and match an invoice to the transaction. This will allow
            the software to trach which Invoices have been paid and which
            Invoices have outstanding balances receivable.<br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("link.png")}
                style={{ margin: 15 }}
              ></img>
            )}
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="6" style={{ textAlign: "left" }}>
              6. Simultaneous edits
            </h4>
            The "Edit All" option allows for editing large transactions
            together. It takes effect after clicking the Save button. Columns
            eligible for Edit all options are the Date, Account Group and Vat
            Type columns. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("editAll.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> <br></br> They are underlined when Edit All is selected.
            The Save button is green when there is no data to be saved, and blue
            when there is data not yet saved. The Enable Delete option enables
            the delete functionality, and also closes the delete option, on the
            2nd click. It is recommended that the delete button is not enabled
            in general use.<br></br> <br></br> The Search option allows users to
            filter transaction data. The Opening balance column must have
            payments, as negative numbers with a negative sign, and receipts as
            positive numbers.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("openingBalance.png")}
                style={{ margin: 15 }}
              ></img>
            )}
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="7" style={{ textAlign: "left" }}>
              7. Pagination
            </h4>
            The Accounts page supports pagination, and the previous and next
            buttons are enabled once there are more than 10 pages. Transactions
            marked as Historical are allocated to the historical tab.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("historical.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="8" style={{ textAlign: "left" }}>
              8. Trial balance
            </h4>
            While links are provided for trial balance line item accounts, to
            view them in the ledger, links from the ledger to the Accounts and
            journal page currently do not show accounts that are not within the
            selected year. When clicking on a ledger account that is within the
            selected year, the ledger will reroute to that entry, however if the
            entry on the ledger is not from the year selected on the Info page,
            it will currently not show. The related bank account must also be
            selected.<br></br> <br></br> To export the ledger to a CSV file,
            click the Prepare CSV button, and thereafter click on Download CSV
            to export the ledger data to a CSV file.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("ledger.png")}
                style={{ margin: 15 }}
              ></img>
            )}{" "}
            <br></br>
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="invoiceID" style={{ textAlign: "left" }}>
              9. Invoices
            </h4>
            The Invoices page has options for creating Invoices. The Add Invoice
            page is used to add initial invoice data. The Invoices Added page
            can be used to edit and add further invoice data including products
            and services. It should be noted that when an invoice is saved on
            the Invoices Added page, a journal entry is automatically passed, to
            credit Sales and debit Trade Receivables, therefore payment is made
            by the custormer, the transaction should be allocated to Trade
            receivables on the Accounts page. The products and services page can
            be used to save products or services, that can be added to invoices.
            The Add business page can be used to save customer or supplier
            details, which can then be added to invoices or quotes. Logos added
            should be small in size and preferably less than 1 mb in size.
            <br></br>
            <br></br>
            FilingStack Accounting software also offers a Pay Now button on
            invoices sent to clients. This allows your clients to pay you via{" "}
            <a target="_blank" rel="noreferrer" href="https://payfast.io/">
              Payfast
            </a>
            . This can be enabled on the Invoices Added page and requires your
            Payfast merchant ID to be entered on the Invoices Added page. Visit
            the{" "}
            <a target="_blank" rel="noreferrer" href="https://payfast.io/">
              Payfast
            </a>{" "}
            website to create a Payfast account. Please ensure that the split
            payments option is enabled on the Payment methods page under the
            Settings link on the Payfast Dashboard. Commission of 3.6% + R2 +
            Vat is deducted to cater for Payfast payment processing fees. Eg; if
            you send an invoice with an amount of R100 and the Pay Now button
            enabled, after your client pays you via Payfast, the amount you'll
            receive will be R93.56 (R100 - ((3.6% of R100) - R2 - (15% vat on
            (3.6% of R100 + R2)))). We recommend adding this additional amount
            onto your invoice before you send it to a client.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("invoices.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="10" style={{ textAlign: "left" }}>
              10. Edit account names
            </h4>
            The soCI and soFP pages allow updating the accounts names for the
            financial statements.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="11" style={{ textAlign: "left" }}>
              11. SOCE
            </h4>
            The soCE has checkboxes which are used to enable each column of
            equity. Columns with data should be enabled.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="12" style={{ textAlign: "left" }}>
              12. Journals
            </h4>
            The journals page allows users to add journal entries. Edits to
            Journal entries posted are saved automatically. Journal entries can
            also be uploaded via a CSV file. The CSV file should contain five
            columns, with these reflecting the Date, Journal Name, Journal
            Amount, and the affecting debit and credit accounts. The debit and
            credit accounts must match Accounts already added onto FilingStack.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("journals.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="13" style={{ textAlign: "left" }}>
              13. Vat calculation
            </h4>
            The Tax page has a link to the Vat page. Vat period dates must be
            selected to show Vat transactions for the period.<br></br> <br></br>{" "}
            Transactions for Vat periods that involve multiple financial years,
            must be added per year. The Vat page is enabled through options on
            the Tax page. The Vat page also has options to lockdown dates to
            prevent editing to those transactions and entries on the Accounts
            and journals pages.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("vat.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="14" style={{ textAlign: "left" }}>
              14. Payslips (historical)
            </h4>
            Payslips are only available for the 2023 financial year (March 2022
            - February 2023). <br></br> <br></br>Payslip generation for the 2024
            is not available. The Tax page has options for payslip generation.
            The Payroll pages are enabled through options available on the 'Tax'
            page. The salary Tax page works after at least 1 employee has been
            added, using the Add Employee page. <br></br> <br></br>The salary
            Tax page should be accessed after at least 1 employee has been
            added. Currently tax rates are applicable to the 2023 tax year, for
            South Africa. If users are manually changing the PAYE or UIF values,
            the gross salary must be re-entered. The pay periods worked must be
            saved prior to entering payroll data - to enable the salary
            calculation to work with the correct period.<br></br> <br></br> The
            medical Aid Tax credits must also be saved for the salary
            calculation to use its value. The net to gross option calculates
            assuming a fixed 12 monthly salary.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="15" style={{ textAlign: "left" }}>
              15. Financial statement generation
            </h4>
            The Afs page loads all of the financial statements together into a
            PDF.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("afs.png")}
                style={{ margin: 15, border: "1px dotted" }}
              ></img>
            )}
            <br></br>
            There are checkboxes to select which pages should be included in the
            financials generated. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("pages.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> <br></br> Below the Pdf displayed, there are non-editable
            application pages used for the Pdf display. However there are
            options to change the names of the financial statements, as well as
            the currency symbols and the Notes heading.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("afsHeading.png")}
                style={{ margin: 15, border: "1px dotted" }}
              ></img>
            )}
            <br></br>
            While this page may load automatically, the "Refresh Pdf" button
            must be used to reflect changes made through the Pdf customization
            options or other changes on the Afs page. <br></br>
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("financials.png")}
                style={{ margin: 15, border: "1px dotted" }}
              ></img>
            )}
            <br></br>
            <br></br> <br></br> Clicking on the title description (Pdf
            customization options) on the Afs page, will hide/unhide the Pdf
            customization options table.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="16" style={{ textAlign: "left" }}>
              16. Icons
            </h4>
            On the Navigation menu, there is a blue icon with the first letter
            of a user's email address. Clicking on it links to the Update
            profile page.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("icon.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            On the Settings page, if the company/business name is updated, the
            company must be selected again, from the company list on the Info
            page, otherwise certain pages may have incorrect values (eg; the TB
            page).<br></br> <br></br> When adding a company initially, each
            company/business added, must have a unique name.
          </li>
          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="17" style={{ textAlign: "left" }}>
              17. Payment information
            </h4>
            New clients get at least 1 month of free usage. To subscribe or
            change your subscription, navigate to the 'Payments' page.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("payments1.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            <br></br> <br></br> The pricing for the amount of usage entered
            would then reflect, thereafter click the 'Click to Confirm (Payfast
            only)' button at the bottom of the payments page, if you're
            subscribing for the first time, or the Update Subscription button if
            you are updating your subscription.
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("payments2.png")}
                style={{ margin: 15, border: "1px dotted" }}
              ></img>
            )}
            <br></br>
            Your subscription details will automatically update. If you're
            subscribing for the first time, your subscriptions details may take
            a few minutes to reflect.
            <br></br> <br></br> Currently payment through Payfast is supported.
            Users will be redirected to the Payfast website to complete their
            payment. The Payfast payment method is a recurring billing option,
            where the total charge is debited monthly or annually from your
            account.
            <br></br> <br></br> There are options to update the subscription or
            cancel the subscription. These options are accessible through the
            payments page on the Filingstack website.
            {/* Users paying through EFT should email their invoice from FilingStack
            and proof of payment to{" "}
            <a href="mailto:info@filingstack.com">info@filingstack.com</a>. To
            increase the monthly transaction upload limit, no new companies are
            required to be added. */}
          </li>

          <li
            style={{
              //backgroundColor: "#E7F3F0 ",

              padding: 15,
              marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            {" "}
            <h4 id="18" style={{ textAlign: "left" }}>
              18. Bank feeds
            </h4>
            Bank feeds with Investec bank are supported. To connect a bank feed
            you'll need the following; your Investec client ID, your secret and
            your X-API-Key. These details after available on the Investec online
            website. You'll need to login to{" "}
            <span style={{ fontWeight: "bold" }}>Investec online</span> and
            navigate to the <span style={{ fontWeight: "bold" }}>Tools</span>{" "}
            page, thereafter on the Programmable banking page, select the{" "}
            <span style={{ fontWeight: "bold" }}>Individual Connections</span>{" "}
            tab and click the{" "}
            <span style={{ fontWeight: "bold" }}>Create API key</span> button.
            The API key allows you to control how you can connect with the
            Investec API. (Further details can be found at
            https://investec.gitbook.io/programmable-banking-community-wiki/get-started/api-quick-start-guide/how-to-get-your-api-keys)
            Once you have your API key, you can create a connection with
            FilingStack. If you are unfamiliar with this process, we recommend
            consulting your Investec private banker.
            <br></br>
            <br></br>
            It's important that you have already added a company and added a
            bank account on FilingStack, before connecting a bank feed, as the
            bank feed transactions will be imported into the currently selected
            bank account. To select a bank account, use the Select Bank Account
            option on the Babk Update page. Here's a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/feed/update/urn:li:activity:7132752146315235330"
            >
              video
            </a>{" "}
            showing how to connect an Investec bank feed to FilingStack.
            Thereafter to set up Investec bank feeds on FilingStack Accounting
            software, navigate to the{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="./bankUpdate"
              target="_blank"
              rel="noreferrer"
            >
              Bank Update
            </a>{" "}
            page and select the Connect bank feeds tab, then click the{" "}
            <span style={{ fontWeight: "bold" }}>Connect Bank Feeds</span>{" "}
            button and enter your Investec Client ID, secret and API Key.
            Thereafter select a profile and bank account to link to the bank
            account that's currently selected on FilingStack. <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("InfoNav.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br> After you click View transactions, you'll be able to
            filter the transaction dates before you import the transactions.
            Once you click Connect and Import transactions, the bank feed
            connection data is saved and the transactions imported can be viewed
            on the Accounts page. Thereafter to refresh the bank feeds after a
            few days or monthly, click the{" "}
            <span style={{ fontWeight: "bold" }}>Refresh bank feeds</span> link
            on the Accounts page. The Bank Update page should be used to connect
            a bank feed initially and the Refresh bank feeds link thereafter.
            The Bank Update page also has options to Disconnect a bank feed or
            reconnect a bank feed (for example; if your login credentials have
            changed).
            <br></br>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("connectBank.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            Bank feed login credentials are encrypted and not available for
            viewing by any user. FilingStack pulls the transaction data from a
            read-only API developed by Investec Bank. No payments can be made
            using the API.
          </li>

          <li
            style={{
              //backgroundColor: "#E7F3F0 ",
              padding: 15,

              marginLeft: -5,
              margin: 20,

              borderRadius: 15,
              listStyleType: "none",
            }}
          >
            <h4
              id="19"
              style={{ textAlign: "left", textDecoration: "underline" }}
            >
              19. Quickstart
            </h4>
            <h5 style={{ textAlign: "left" }}>Intro. </h5>
            <h5 style={{ textAlign: "left" }}>
              Filingstack Accounting software allows clients to upload their
              businesses bank statements to automatically generate a ledger,
              trial balance and financial statements. A client may also export
              the trial balance and upload it to another software to generate
              financials.
            </h5>
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              1. Create an account by signing up.
            </h5>
            <br></br>
            <h5 style={{ textAlign: "left" }}>2. Log in.</h5>
            <br></br>
            <h5 style={{ textAlign: "left" }}>
              3. Click on the Demo company on the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./AfterLoginPage"
                target="_blank"
                rel="noreferrer"
              >
                Info
              </a>{" "}
              page. This selects the Demo company. A company must always be
              selected.
            </h5>{" "}
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("InfoPage.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>
            <span style={{ textAlign: "left" }}>
              {" "}
              To generate accounting reports (Trial balance, ledger, financial
              statements), continue with these steps.{" "}
            </span>
            <span style={{ textAlign: "left" }}>
              See below for notes on generating invoices, vat reports (found on
              the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./ProvTax"
                target="_blank"
                rel="noreferrer"
              >
                Tax
              </a>{" "}
              page).{" "}
            </span>
            <span style={{ textAlign: "left" }}>
              If you require assistance, send a support request via the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./Support"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>{" "}
              page, alternatively send an email to info@filingstack.com or let
              us know via the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./ContactPage"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>{" "}
              page.
            </span>
            <h5 style={{ textAlign: "left" }}>
              <br></br>
              4. Add a bank account on the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./Accounts"
                target="_blank"
                rel="noreferrer"
              >
                Accounts
              </a>{" "}
              page.
            </h5>
            {LoadingCheck(
              <img
                alt="Add bank account visual"
                src={DisplayImage("AddBankAccount.png")}
                style={{ margin: 20 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              5. Upload your businesses/clients bank statements in the CSV
              format, on the Accounts page (Click 'Choose file', then 'Upload
              file'). Alternatively, download this{" "}
              <span
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={DownloadCSV}
              >
                sample
              </span>{" "}
              CSV file and upload it. See below for more details on uploading
              CSV (Comma separated values) files. Clients can download their
              bank statements in the CSV format from their online banking, or
              alternatively convert their PDF statements to CSV.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("UploadFile.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              6. Allocate each transaction to an Account and Click the Save
              button on the Accounts page.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("AllocateAccounts.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              7. View the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./Tbalance"
                target="_blank"
                rel="noreferrer"
              >
                TB (trial balance)
              </a>{" "}
              and{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./Ledger"
                target="_blank"
                rel="noreferrer"
              >
                Ledger
              </a>{" "}
              pages to review the data you've added.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("TrialBalance.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              8. Post journals if required via the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./journals"
                target="_blank"
                rel="noreferrer"
              >
                Journals
              </a>{" "}
              page.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("journals.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              9. Use the soFP (statement of Financial Position), soCI (statement
              of Comprehensive Income), soCE (statement of Changes in Equity)
              and cashflow pages to edit account names that reflect on the AFS
              (Annual financial statement).
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("soCI.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              10. Use the Reports link on the General page, to add reports to
              the beginning of the AFS, and the Notes page to add additional
              notes at the end of the AFS.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("reports.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              11. To change the company name, navigate to the Info page and
              click the 'Settings' link. On the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./settings"
                target="_blank"
                rel="noreferrer"
              >
                Settings
              </a>{" "}
              page, you can change the company's name and other details.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("settings.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              12. On the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./PdfDisplay"
                target="_blank"
                rel="noreferrer"
              >
                AFS
              </a>{" "}
              page, you can print the AFS (Annual financial statement) in the
              PDF format.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("afs.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              13. To add a new company, use the Add Entity link, available on
              the Info page. Click{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://youtu.be/wBploRRT5uo"}
              >
                here
              </a>{" "}
              to watch a video on how to add additional companies onto your
              profile.
            </h5>
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("payments.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            {LoadingCheck(
              <img
                alt="Visual"
                src={DisplayImage("payments1.png")}
                style={{ margin: 15 }}
              ></img>
            )}
            <br></br>{" "}
            <h5 style={{ textAlign: "left" }}>
              14. After you have added a new company, you must select that
              company on the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./AfterLoginPage"
                target="_blank"
                rel="noreferrer"
              >
                Info
              </a>{" "}
              page by clicking on companies name. Thereafter you may follow
              steps 4 to 11 as listed above.
            </h5>
          </li>

          <div>
            {" "}
            <h4 id="20" style={{ padding: 15, marginLeft: 20 }}>
              20. Quickstart videos (We recommend watching).
            </h4>
            <div style={{}}>
              <h5 style={{ padding: 15, marginLeft: 20 }}>
                A brief overview of Filingstack Accounting software.
              </h5>
              <iframe
                style={{ padding: 15, marginLeft: 20, marginBottom: 50 }}
                width="100%"
                height="515"
                src="https://www.youtube.com/embed/_v6jW4khiNQ?si=uFuMxEqurXNnu6PH"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
              <h5 style={{ padding: 15, marginLeft: 20 }}>
                How to upload bank statements onto Filingstack Accounting
                software.
              </h5>
              <iframe
                style={{ padding: 15, marginLeft: 20, marginBottom: 50 }}
                width="100%"
                height="515"
                src="https://www.youtube.com/embed/zQf7mQkCeUE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
              <h5 style={{ padding: 15, marginLeft: 20 }}>
                How to add a company onto your profile.
              </h5>
              <iframe
                style={{ padding: 15, marginLeft: 20, marginBottom: 50 }}
                width="100%"
                height="515"
                src="https://www.youtube.com/embed/wBploRRT5uo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
              <h5 style={{ padding: 15, marginLeft: 20 }}>
                How to process bank statement transactions.
              </h5>
              <iframe
                style={{ padding: 15, marginLeft: 20, marginBottom: 50 }}
                width="100%"
                height="515"
                src="https://www.youtube.com/embed/ZLYIzV4JeV4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div>
            {" "}
            <h4 id="21" style={{ padding: 15, marginLeft: 20 }}>
              21. Advertising.
            </h4>
            <p style={{ padding: 15, marginLeft: 20 }}>
              If you run an accounting practice, you can click Advertise on the
              Dashboard page, and add your practice details. This will enable
              your practice to be included on our Accounting Practice Directory
              page, where people can view your practice details and help you
              gain more clients.
            </p>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default UsageInfo;
