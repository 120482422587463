import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForAuthorisation } from "./context";
import { auth, database } from "./firebase";
let initialrender1 = true;
let renderRef = true;

const Logout = () => {
  //logout is is used by function logout below, & it comes from context.js & is linked to context hook
  const { logout } = useForAuthorisation();
  const historyPage = useHistory();
  const [errorMessage, setErrorMessage] = useState();
  let MainUserUID = auth.currentUser.uid;
  const [getUsers, setGetUsers] = useState([]);
  const [newData, setNewData] = useState([]);
  // const [uid, setUID] = useState("");
  useEffect(() => {
    database.ref("Owner/" + MainUserUID).on("value", (snapshot) => {
      const accounts = snapshot.val();

      let allAccounts = [];
      for (let id in accounts) {
        allAccounts.push({ id, ...accounts[id] });
      }

      setGetUsers(allAccounts.filter((el) => el.MAINuserId));
    });

    //  setLoadingApp1(false);

    return () => database.ref("Owner/" + MainUserUID).off();
  }, [MainUserUID]);
  //
  useEffect(() => {
    if (initialrender1) {
      initialrender1 = false;
      return;
    }
    const urlDb = "/UserSelections/" + MainUserUID;
    const newUrlDb = database.ref(urlDb);
    newUrlDb.on("value", (snapshot) => {
      const accounts = snapshot.val();

      let newAccounts = [];

      newAccounts.push({ ...accounts });

      setNewData(newAccounts);
    });
    return () => newUrlDb.off();
  }, [MainUserUID]);
  //
  const [uid, setUID] = useState("");

  useEffect(() => {
    if (renderRef) {
      renderRef = false;
      return;
    }
    getUsers.map((el) => setUID(el.MAINuserId));
  }, [getUsers]);

  const [companySelectedIdKey, setcompanySelectedIdKey] = useState("");

  useEffect(() => {
    setcompanySelectedIdKey(
      newData
        .filter((t) => t.companySelected)
        .map((el) => el.companySelected)
        .toString()
    );
  }, [newData]);

  const [CompanyNAMEDetails, setCompanyNAMEDetails] = useState("");
  const [CompanyYEAR, setCompanyYEAR] = useState("");
  //FOR COMPANY NAME

  useEffect(() => {
    if (uid && companySelectedIdKey) {
      const updateUrlDb =
        "/BusinessDetails/" +
        uid +
        `/ClientCompanyAdded/${companySelectedIdKey}/companyNameRef`;

      const newUpdateUrlDb = database.ref(updateUrlDb);
      const updateUrlDbYEAR =
        "/BusinessDetails/" +
        uid +
        `/ClientCompanyAdded/${companySelectedIdKey}/yearEndDate`;

      const newUpdateUrlDbYEAR = database.ref(updateUrlDbYEAR);

      newUpdateUrlDb.on("value", (snapshot) => {
        const accounts = snapshot.val();

        setCompanyNAMEDetails(accounts); //need to filter by which id matches the current company id selected so that only
        ///the current company selected
      });
      newUpdateUrlDbYEAR.on("value", (snapshot) => {
        const accounts = snapshot.val();

        setCompanyYEAR(accounts); //need to filter by which id matches the current company id selected so that only
        ///the current company selected
      });

      return () => {
        newUpdateUrlDb.off();
        newUpdateUrlDbYEAR.off();
      };
    }
  }, [companySelectedIdKey, uid]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");

      // firebase function to logout user -from context file
      await logout();

      //

      localStorage.removeItem("noSelection");
      // we can use useHistory from react router to push to the component in brackets.
      historyPage.push("/FeedBack");
    } catch {
      setErrorMessage("Logout unsuccessful");
    }
  };

  return (
    <div>
      <nav>
        <Link
          to="/settings"
          title={CompanyNAMEDetails}
          className="btn btn-link"
        >
          {(
            (CompanyNAMEDetails && (CompanyNAMEDetails || "")?.toString()) ||
            ""
          )?.slice(0, 10) +
            " " +
            (((CompanyYEAR || "").toString() || "")?.slice(0, 4) || "")}
        </Link>

        <button className="btn btn-link" onClick={handleLogout}>
          Logout
        </button>
      </nav>
      {errorMessage && (
        <div className="alert alert-warning" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
export default Logout;
