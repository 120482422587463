//import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/functions";
import "firebase/compat/app-check";
import "firebase/compat/analytics";
import "firebase/compat/storage";

// const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require("firebase/app-check");
// const { initializeApp } = require("firebase/app");
// //import { getDatabase, ref, onValue } from "firebase/database";
// import { getAuth } from "firebase/auth";
// const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require("firebase/app-check");
//import "firebase/firestore";

// used for authentication
//var database = firebase.database();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = firebase.initializeApp({
  apiKey: "AIzaSyBYnc2ysweStJ-TIhjcbNkpfKKjq83T2Zo",
  authDomain: "filing-stack.firebaseapp.com",
  databaseURL: "https://filing-stack-default-rtdb.firebaseio.com",
  projectId: "filing-stack",
  storageBucket: "filing-stack.appspot.com",
  messagingSenderId: "988772706269",
  appId: "1:988772706269:web:40943029a378ab7a1ab834",
  measurementId: "G-K97Q70EFJP",
});

// Initialize Firebase
//const app = firebase.initializeApp(firebaseConfig);
//remove in production
//
//window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
//
//
const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  "6Lch3wMdAAAAAJrP7njIi78LH3IQVZVUUNEC2D5j",

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
);

//export const database = firebase.database();
// Get a reference to the database service
export const database = firebase.database();
//export const refs = ref;
//export const onValues = onValue;

//
//export const fireStoreDb = firebase.firestore();
export const auth = app.auth();
//auth.useEmulator("http://localhost:9099");

export const functions = firebase.app().functions("us-central1");
export const analytics = firebase.analytics();
export const storage = firebase.storage(app);

//export const Appfunctions = app.functions();
//removed export default app, may have to bring back
//export const app;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//reason for REACT_APP_FIREBASE_ before each config is react environmental variables must be prefixed with REACT_APP
