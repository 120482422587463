import React, { useState, useEffect, useRef } from "react";

import "./cover.css";

import { auth } from "./firebase";

//import Checkbox from "@material-ui/core/Checkbox";
import LinearIndeterminate from "./loading.js";

import { database } from "./firebase";
import { getDatabase, ref, onValue, update } from "firebase/database";
import Navigation from "./navigation";

import NotesNav from "./NotesNav";

//import pic from "./pexels-pixabay-268415.jpg" ;

const Cover = () => {
  // let uid = auth.currentUser.uid;
  const [newData, setNewData] = useState([]);
  const [loadingApp1, setLoadingApp1] = useState(true);
  // const [loadingApp2, setLoadingApp2] = useState(true);
  let MainUserUID = auth.currentUser.uid;
  const [getUsers, setGetUsers] = useState([]);

  useEffect(() => {
    let stop = false;
    if (stop) return;
    database.ref("Owner/" + MainUserUID).on("value", (snapshot) => {
      const accounts = snapshot.val();
      // console.log(accounts);
      let allAccounts = [];
      for (let id in accounts) {
        allAccounts.push({ id, ...accounts[id] });
      }

      setGetUsers(allAccounts.filter((el) => el.MAINuserId));
    });

    //  setLoadingApp1(false);

    return () => {
      database.ref("Owner/" + MainUserUID).off();
      stop = true;
    };
  }, [MainUserUID]);

  // console.log(getUsers);
  const [uid, setUID] = useState("");
  const renderRef = useRef(true);
  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false;
      return;
    }
    getUsers.map((el) => setUID(el.MAINuserId));
  }, [getUsers]);

  // console.log(uid, MainUserUID);

  useEffect(() => {
    const urlDb = "/UserSelections/" + MainUserUID;
    const newUrlDb = database.ref(urlDb);
    setLoadingApp1(true);
    newUrlDb.on("value", (snapshot) => {
      const accounts = snapshot.val();
      //   console.log(snapshot.val());

      let newAccounts = [];

      newAccounts.push({ ...accounts });

      setNewData(newAccounts);
    });
    setLoadingApp1(false);
  }, [MainUserUID]);
  const [companySelectedIdKey, setcompanySelectedIdKey] = useState([]);

  useEffect(() => {
    setcompanySelectedIdKey(newData.map((el) => el.companySelected).toString());
  }, [newData]);

  //this sets the style of the navbar name when component loads
  const [stylingOnClickCover, setStylingOnClickCover] = useState();
  useEffect(() => {
    setStylingOnClickCover("MediumBlue");
  }, []);

  const [noBusinessSelected, setnoBusinessSelected] = useState();
  useEffect(() => {
    let storage = localStorage.getItem("noSelection");
    setnoBusinessSelected(storage);
  }, []);
  // function longDateWording(el) {
  //   let dateNumber = el.slice(8, 11);
  //   let dateMonth = el.slice(5, 6);
  //   let dateMonthValue = (dateMonth = "02"
  //     ? "February"
  //     : "03"
  //     ? "March"
  //     : "04"
  //     ? "April"
  //     : "05"
  //     ? "May"
  //     : "06"
  //     ? "June"
  //     : "07"
  //     ? "July"
  //     : "08"
  //     ? "August"
  //     : "09"
  //     ? "September"
  //     : "10"
  //     ? "October"
  //     : "11"
  //     ? "November"
  //     : "12"
  //     ? "December"
  //     : "01"
  //     ? "January"
  //     : "");
  //   let dateYear = el.slice(0, 4);
  //   return dateNumber + " " + dateMonthValue + " " + dateYear;
  // }
  const [defaultValue, setdefaultValue] = useState(
    "Annual financial statements for the year ending"
  );
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyDATE, setCompanyDATE] = useState("");

  useEffect(() => {
    let stop = false;
    if (stop) return;
    const db = getDatabase();
    let dataref =
      "BusinessDetails/" +
      uid +
      "/ClientCompanyAdded/" +
      companySelectedIdKey +
      "/coverPage";
    //
    let datarefYEARENDDATE =
      "BusinessDetails/" +
      uid +
      "/ClientCompanyAdded/" +
      companySelectedIdKey +
      "/yearEndDate";
    //
    let datarefName =
      "BusinessDetails/" +
      uid +
      "/ClientCompanyAdded/" +
      companySelectedIdKey +
      "/companyNameRef";
    //
    const coverRef = ref(db, dataref);
    const coverRefName = ref(db, datarefName);
    const coverRefDate = ref(db, datarefYEARENDDATE);
    onValue(coverRef, (snapshot) => {
      const data = snapshot.val();

      setdefaultValue(data);
    });
    onValue(coverRefName, (snapshot) => {
      const data = snapshot.val();

      setCompanyName(data);
    });
    onValue(coverRefDate, (snapshot) => {
      const data = snapshot.val();

      setCompanyDATE(data);
    });
    return () => {
      stop = true;
    };
  }, [uid, companySelectedIdKey]);

  //

  //  console.log("render");
  const handleState = (e) => {
    setdefaultValue(e.target.value);
  };
  const handleChange = (e) => {
    const db = getDatabase();
    update(
      ref(
        db,
        "BusinessDetails/" + uid + "/ClientCompanyAdded/" + companySelectedIdKey
      ),
      {
        coverPage: e.target.value,
      }
    )
      .then(() => {
        // Data saved successfully!
      })
      .catch((error) => {
        console.log(error);
        // The write failed...
      });
  };

  //
  if (noBusinessSelected === "true") {
    return (
      <>
        <Navigation />
        <div
          // style={{ marginRight: 115, marginTop: 10 }}
          className="spinner-border text-primary"
          role="status"
        ></div>
        <div style={{ margin: 10, padding: 10 }}>
          Please note that at least one business must be selected, from the
          business list on the "Info" page. Please select a business from the
          "Info" page. If no businesses are shown on the "Info" page, click "Add
          Entity" on the "Info" page to add a business. If the browsers cache or
          local storage has been cleared, please login again.
        </div>
      </>
    );
  }

  //

  return (
    <div>
      <Navigation
        colorNotesMenu="MediumBlue"
        stylingOnClickCover={stylingOnClickCover}
      />
      <NotesNav coverColor="MediumBlue" />
      {loadingApp1 && (
        <div style={{ margin: 5, padding: 5 }}>
          <LinearIndeterminate />
        </div>
      )}
      <div className="covercss">
        <h6>
          Note: Changes to the wording below reflects on the cover page of the
          AFS.
        </h6>
        {/* uses business details not user /accounts */}
        <h1>{CompanyName}</h1>
        <h2>
          <input
            style={{ width: "65%" }}
            value={defaultValue}
            onChange={handleState}
            onBlur={handleChange}
          ></input>

          {`${
            newData.map((el1) => Object.keys(el1).length).toString() == 0
              ? []
              : CompanyDATE ||
                newData.map((el5) => el5.yearSelected.replace(/-/g, "/"))
          }`}
        </h2>
      </div>
      {/* <img src={pic}/> */}
    </div>
  );
};

export default Cover;
