import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//we import  the auth object from the firebase file, this is to set the logged in user to the logged in user, etc
import { auth } from "./firebase";

import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const FirebaseContext = React.createContext();

// function to use Context below. custom hook?
export function useForAuthorisation() {
  return useContext(FirebaseContext);
}
//main function
export function FirebaseAuth({ children }) {
  const [loggedInUser, setLoggedInUser] = useState();
  const [loading, setLoading] = useState(true);
  var provider = new firebase.auth.GoogleAuthProvider();
  // firebase has its own function to get and set the current user, we call this function in the signup component.
  // we first pass it as props to the Provider below.

  let location = useLocation();
  function signupUser(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }
  function signInWithGoogle(notUsed) {
    return auth.signInWithPopup(provider);
  }

  // let { from } = location.state || { from: { pathname: "Login" } };
  async function login(email, password) {
    //local was previously session but new tabs required re login
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await auth.signInWithEmailAndPassword(email, password); //firebase specific
  }

  function logout() {
    return auth.signOut();
  }

  function emailVerify() {
    return auth.currentUser.sendEmailVerification();
  }
  function ResetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }
  function updateLoggedInUsersPassword(newpass) {
    return loggedInUser.updatePassword(newpass);
  }
  function updateLoggedInUsersEmail(emailUpdate) {
    return loggedInUser.updateEmail(emailUpdate);
  }
  function deleteUserProfile(user) {
    return auth.currentUser.delete();
  }
  function ReauthenticateWithCredential(email, pass) {
    return auth.currentUser.reauthenticateWithCredential(email, pass);
  }
  let userDetails = auth && auth.currentUser;
  let name, emailDetails;
  //photoUrl, uid, emailVerified;

  if (userDetails != null) {
    name = userDetails.displayName;
    emailDetails = userDetails.email;

    //  photoUrl = userDetails.photoURL;
    //  emailVerified = userDetails.emailVerified;
    //  uid = userDetails.uid; // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
  }
  //useEffect so it only runs once, we also unsubscribe the current user
  useEffect(() => {
    const unsubscribe =
      auth &&
      auth.onAuthStateChanged((user) => {
        setLoggedInUser(user);
        // console.log("123456");
        setLoading(false);
      });

    //unsubscribe();
    // the above firebase method returns a method which unsubscribes users when we return/unmount the component
    return unsubscribe;
  }, []);

  // //added recently
  //   const [isLoggedIn, setLoggedIn] = useState(false);

  //   function readSession() {
  //     const user = window.sessionStorage.getItem(
  //       `firebase:authUser:${auth.apiKey}:[DEFAULT]`
  //     );
  //     if (user) setLoggedInUser(true);
  //   }
  //   useEffect(() => {
  //     readSession();
  //   }, []);

  // value is an object that we pass as props to FirebaseContext.provider which provides theses object value props to all
  // its nested components, etc. the objects props are passed throuhout our components.
  const value = {
    loggedInUser,
    signupUser,
    signInWithGoogle,
    login,
    logout,
    emailVerify,
    loading,
    ResetPassword,
    updateLoggedInUsersPassword,
    updateLoggedInUsersEmail,
    deleteUserProfile,
    ReauthenticateWithCredential,
    emailDetails,
    name,
    location,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}
