import React, { useState } from "react";

import { Link } from "react-router-dom";
//import picture from "./pictures/pic6.jpg";
//import picture1 from "./pictures/pic2.png";
import picture2 from "./pictures/pic8.png";
import picture3 from "./pictures/TB.png";
import Modal from "react-modal";

import "./home.css";
import Footer from "./Footer";
import HomePage from "./HomePage";
//import ContactPage from "./ContactPage";
const HomePageContent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const [loaded, setLoaded] = useState(false);
  const handleLoaded = () => {
    setLoaded(true);
  };
  // console.log(loaded);
  let boxShadow = "rgb(222 251 236 / 45%) 0px 1px 2px 2px"; // previous 'rgb(120 225 191 / 45%) 0px 1px 2px 2px'
  return (
    <div>
      <div
        className="mobile3"
        style={{
          width: "100%",
          // `url(${picture})` ||
          backgroundImage:
            "linear-gradient(45deg, rgb(189, 243, 255), rgb(238, 255, 247))",

          // "linear-gradient(45deg, rgb(0, 0, 0), rgb(24, 67, 169))", //,
          //backgroundColor: "rgb(227 237 236)",
          marginBottom: 15,
        }}
      >
        <HomePage
        // url={`url(${picture})`}
        />
        <h1
          className="mobile10"
          style={{
            color: "black",
            // textAlign: "left",
            // padding: 20,
            // fontSize: "390%",
            // width: "50%",
            fontFamily: "calibri",
          }}
        >
          Accounting Software for <br className="mobile17"></br>easier
          accounting.
        </h1>{" "}
        <img
          className="imageStyle"
          style={
            {
              // width: "50%",
              //marginLeft: "45%", marginTop: "-15%"
            }
          }
          src={picture2}
          alt="Accounts"
        ></img>
        <h5
          className="mobile15"
          style={{
            marginLeft: "1%",
            // marginRight: "1%",
            // marginBottom: "1%",
            //  marginTop: "-10%",
            padding: "1%",
            paddingBottom: "20%",
            //   textAlign: "left",
          }}
        >
          {" "}
          Invoicing, and intuitive accounting software.
        </h5>
      </div>

      {/* <img style={{ width: "100%", height: 500 }} src={picture}></img> */}
      <div>
        {/* <h5
          style={{
            margin: 25,
            marginTop: 40,
            marginBottom: 0,
            // backgroundColor: 'rgb(247 247 247 / 1)',//"#DDE8E4"
            textAlign: "center",
          }}
        >
          Welcome to FilingStack Accounting
        </h5> */}
        <div style={{ display: "inline-block" }}>
          <div
            // className="mobile"
            style={{
              //  marginRight: -75,
              //backgroundColor: "#DDE8E4",
              //  textAlign: "left",
              //    backgroundColor: "rgb(247 247 247)",

              // padding: 15,

              borderRadius: 15,
              // listStyleType: "none",
              //display: "grid",
              // float: "left",
              //   width: "95%",
              padding: 40,
            }}
          >
            <div>
              {" "}
              <h5>
                FilingStack Accounting offers simple, effective accounting
                software.
              </h5>{" "}
            </div>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h5>
                {" "}
                Filingstack Accounting is designed for your accounting
                processing.
              </h5>
              <div
                style={{
                  marginLeft: "15%",
                  marginRight: "15%",
                  fontSize: "larger",
                }}
              >
                {" "}
                <svg
                  onClick={handleModal}
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="blue"
                  class="bi bi-play"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
                </svg>{" "}
                <button
                  className="btn btn-link"
                  style={{ fontSize: 20, color: "black" }}
                  onClick={handleModal}
                >
                  A video on FilingStack Accounting in under 4 minutes.
                </button>{" "}
                {/* <a
                  style={{ fontWeight: "bold" }}
                  href="./About"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a> */}
              </div>
              {/* Currently, clients who sign up, qualify for the beta free usage
              program - allowing free usage beyond the trial period. (Fair usage
              limits may apply). */}
            </div>{" "}
            <div>
              <Modal isOpen={modalOpen}>
                {/* - added due to aria messages being logged. */}
                {Modal.setAppElement("#root")}
                <iframe
                  onLoad={handleLoaded}
                  // style={{ marginLeft: "10%" }}
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/p23j2LW-GJw?si=BQCaueek53OiJl46"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>

                {/* <iframe
                  onLoad={handleLoaded}
                  src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7147464301795094530?compact=1"
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  allowFullScreen=""
                  title="Embedded post"
                ></iframe> */}
                {!loaded ? (
                  <div
                    style={{
                      marginLeft: "50%",
                    }}
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                ) : null}
                <button
                  style={{ margin: 20, padding: 10 }}
                  onClick={handleModalClose}
                  className="btn btn-primary"
                >
                  Close
                </button>
              </Modal>
            </div>
            {/* <div>
              {" "}
              Filingstack software integrates accounting records with financial
              statement generation, together with options for payroll and
              invoice generation.
            </div> */}
            <div style={{ marginTop: 40 }}>
              {" "}
              <Link
                className="mobile13"
                style={{
                  padding: 15,
                  backgroundColor: "#00b8ff",
                  fontWeight: "bold",
                  borderRadius: 15,
                  color: "white",

                  display: "block",

                  // backgroundImage:
                  //   "linear-gradient(45deg, rgb(12 46 129), rgb(26 70 171))",
                  fontSize: "x-large",
                }}
                to="/Signup"
              >
                Sign up for free
              </Link>
            </div>{" "}
          </div>{" "}
          <div>
            <h5
              style={{
                margin: 15,
                padding: 15,
                backgroundColor: "rgb(210, 241, 253)",
                backgroundImage:
                  "linear-gradient(45deg, rgb(220 243 255), rgb(178, 225, 243))",
                borderRadius: 15,
              }}
            >
              {" "}
              Read the{" "}
              <a
                style={{ fontWeight: "bold" }}
                href="./UsageInfo"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>
              , take a{" "}
              <Link
                style={{ fontWeight: "bold" }}
                target="_blank"
                rel="noreferrer"
                to="/Tutorial"
              >
                tutorial
              </Link>
              , or watch our quickstart{" "}
              <a
                style={{ fontWeight: "bold" }}
                target="_blank"
                rel="noreferrer"
                href="https://youtube.com/@filingstackaccounting4825?feature=shared"
              >
                videos
              </a>{" "}
              to get started.
            </h5>
          </div>
          <div>
            <h5
              style={{
                margin: 15,
                padding: 15,
                backgroundColor: "rgb(210, 241, 253)",
                backgroundImage:
                  "linear-gradient(45deg, rgb(216 246 255), rgb(170 231 255))",
                borderRadius: 15,
              }}
            >
              Trusted by accountants and businesses. Developed in South Africa
              by Accountants.
            </h5>
          </div>
          <div className="mobile6">
            <div className="mobile12">
              <h5
                style={{
                  margin: 15,
                  padding: 15,
                  backgroundColor: "#d2f1fd",
                  backgroundImage:
                    "linear-gradient(45deg, #99e9ed, transparent)",
                  borderRadius: 15,
                }}
              >
                {" "}
                <a href="./ContactPage" target="_blank" rel="noreferrer">
                  Contact Us.
                </a>
              </h5>
            </div>
            <div className="mobile12">
              <h5
                style={{
                  margin: 15,
                  padding: 15,
                  backgroundColor: "#d2f1fd", //"rgb(194 229 249)"
                  borderRadius: 15,
                  backgroundImage:
                    "linear-gradient(45deg, #99e9ed, transparent)",
                }}
              >
                {" "}
                <a href="./Pricing" target="_blank" rel="noreferrer">
                  See pricing.
                </a>
                <Link to="/Pricing"></Link>
              </h5>
            </div>
          </div>
          <div>
            {" "}
            <div
              className="mobile8"
              style={{
                //  marginTop: 15,

                // borderRadius: 15,
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                // backgroundColor: "rgb(206 237 245)",
                // margin: 15,
              }}
            >
              <div className="mobile6">
                <div
                  className="mobile9"
                  style={{
                    margin: 5,
                    padding: 40,
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    textAlign: "left",
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  <h2> Professional Invoices</h2>

                  <div>
                    Send professional invoices and quotes to your clients, and
                    get paid faster.{" "}
                  </div>
                </div>
                <div
                  className="mobile7"
                  style={{
                    //  backgroundColor: "rgb(247 247 247)",
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    // borderRadius: 15,
                    margin: 5,
                    textAlign: "left",
                    padding: 40,
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  <h2>Easily accessible.</h2>
                  <div>Saved online and accessible from many devices.</div>
                </div>
              </div>
              <div className="mobile6">
                <div
                  className="mobile9"
                  style={{
                    //backgroundColor: "rgb(247 247 247)",
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    // borderRadius: 15,
                    margin: 5,
                    padding: 40,
                    textAlign: "left",
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  <h2>AI Extract</h2>
                  <div>Upload PDF bank statements for conversion to CSV.</div>
                </div>
                <div
                  className="mobile7"
                  style={{
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    // borderRadius: 15,
                    margin: 5,
                    padding: 40,
                    textAlign: "left",
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  <h2>Trial balances</h2>
                  <div>
                    Automatic trial balance reports for financial statement
                    reporting.
                  </div>
                </div>
              </div>
              <div className="mobile6">
                <div
                  className="mobile9"
                  style={{
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    //  borderRadius: 15,
                    margin: 5,
                    padding: 40,
                    textAlign: "left",
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  {" "}
                  <h2> Ledgers</h2>
                  <div>
                    Automatically generated ledger reports for deep insights
                    into your accounting.
                  </div>
                </div>
                <div
                  className="mobile7"
                  style={{
                    fontSize: "x-large",
                    boxShadow: boxShadow,
                    //  borderRadius: 15,
                    margin: 5,
                    padding: 40,
                    textAlign: "left",
                    backgroundColor: "#a4e7e796",
                    backgroundImage:
                      "linear-gradient(45deg, #99e9ed, transparent)",
                  }}
                >
                  <h2>Insightful Financial reports</h2>
                  <div>
                    Impressive financial statements, including profit and loss,
                    balance sheet, cashflow and more.
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        {/* <div style={{ padding: 20, margin: 20 }}>
          <h5 style={{ padding: 5, marginLeft: 20 }}>Invoices</h5>
          <img
            style={{
              maxWidth: "80%",
              // maxHeight: "800px",
              padding: 5,
              margin: 5,
            }}
            src={picture2}
            alt="Visual"
          ></img>
          <h5 style={{ padding: 20, marginLeft: 20, marginTop: 10 }}>
            Financial statements (Extract)
          </h5>
          <img
            style={{
              maxWidth: "100%",
              // maxHeight: "800px",
              padding: 5,
              margin: 5,
            }}
            src={picture3}
            alt="Visual"
          ></img> */}
        {/* <h5 style={{ padding: 15, marginLeft: 20, marginTop: 10 }}>
            An overview of FilingStack accounting software.
          </h5>
          <iframe
            className="mobile11"
            style={{ padding: 5, marginLeft: 5, marginBottom: 20 }}
            //  width="71%"
            height="555"
            src="https://www.youtube.com/embed/_v6jW4khiNQ?si=uFuMxEqurXNnu6PH"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>{" "} */}
        {/* <img
            className="mobile11"
            style={{
              // maxWidth: "70%",
              // maxHeight: "700px",
              padding: 15,
              margin: 15,
            }}
            src={picture4}
            alt="Visual"
          ></img>
        </div> */}
        <div style={{ marginTop: 30, marginRight: 40, marginBottom: 50 }}>
          <div
            className="mobile1"
            style={{
              backgroundColor: "rgb(210 237 253)",
              backgroundImage:
                "linear-gradient(45deg, rgb(153, 233, 237), transparent)",
              color: "black",
              width: "100%",
              padding: 15,
              // marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              //  listStyleType: "none",
              // display: "inline",
              fontWeight: "bold",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-lock-fill"
              viewBox="0 0 16 16"
              style={{ marginRight: 10 }}
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg>
            Data privacy
          </div>
          <div
            className="mobile1"
            style={{
              backgroundColor: "rgb(210 237 253)",
              backgroundImage:
                "linear-gradient(45deg, rgb(153, 233, 237), transparent)",
              color: "black",
              width: "100%",

              padding: 15,
              // marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              fontWeight: "bold",
              // listStyleType: "none",
              // display: "inline",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-cpu"
              viewBox="0 0 16 16"
              style={{ marginRight: 10 }}
            >
              <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
            </svg>
            Highly Efficient
          </div>
          <div
            className="mobile1"
            style={{
              backgroundColor: "rgb(210 237 253)",
              backgroundImage:
                "linear-gradient(45deg, rgb(153, 233, 237), transparent)",
              color: "black",
              width: "100%",
              fontWeight: "bold",
              padding: 15,
              // marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              //listStyleType: "none",
              // display: "inline",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-check-all"
              viewBox="0 0 16 16"
              style={{ marginRight: 10 }}
            >
              <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
            </svg>
            Free Support
          </div>
          <div
            className="mobile1"
            style={{
              backgroundColor: "rgb(210 237 253)",
              backgroundImage:
                "linear-gradient(45deg, rgb(153, 233, 237), transparent)",
              color: "black",
              width: "100%",
              fontWeight: "bold",
              padding: 15,
              //marginLeft: -5,
              margin: 20,
              borderRadius: 15,
              //listStyleType: "none",
              // display: "inline",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-cloud-upload"
              viewBox="0 0 16 16"
              style={{ marginRight: 10 }}
            >
              <path
                fill-rule="evenodd"
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
              />
              <path
                fill-rule="evenodd"
                d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
              />
            </svg>
            Saved online
          </div>
        </div>{" "}
        {/* <div
          style={{
            //backgroundImage: `url(${contactPicture})`,
            height: 200,
            marginBottom: 25,
            backgroundColor: "rgb(231, 252, 255)",
          }}
        >
          <h2 style={{ paddingTop: 70, color: "black" }}>Pricing Overview</h2>
        </div>
        <div>
          <p
            style={{
              marginLeft: 100,
              marginRight: 100,
              padding: 15,
              textAlign: "center",
            }}
          >
            FilingStack offers fixed monthly pricing. There are no hidden fees.
            No unexpected billing. Cancel at any time.
          </p>
        </div>
        <div
          className="mobile6"
          style={{ margin: 10, width: "90%", marginLeft: "5%" }}
        >
          <div
            className="mobile9"
            style={{
              display: "inline-block",
              backgroundColor: "rgb(193 231 238 / 0%) ",
              boxShadow: boxShadow,

              borderRadius: 15,
              //   width: "50%",
            }}
          >
            <div style={{ paddingTop: 10, margin: 5 }}>
              <h2 style={{ textAlign: "center" }}>Accounting plan - R50 </h2>
            </div>
            <ul style={{ textAlign: "left" }}>
              Add a business for{" "}
              <span style={{ fontWeight: "bold" }}>R50 </span>(ZAR) per month.{" "}
              <li style={{ padding: 5 }}>
                This plan is suited for accountants that don't need invoicing,
                but need trial balance generation for their clients.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Process monthly bank statement transactions.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Allocate multiple bank statement transactions together with
                simultaneous edits and link rules.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Analyse your businesses performance and profitability, with
                analytics and financial reports.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Generate financial statements, including profit and loss,
                balance sheet, cashflow, statement of changes in equity, and
                notes to the financial statements.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Add multiple users including your accountant or your staff
                members at no additional cost.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Available online, anywhere, anytime.{" "}
              </li>
              <li style={{ padding: 5 }}>Process Vat calculations.</li>
              <li style={{ padding: 5 }}>
                Convert PDF bank statements to CSV (Comma separated values which
                is a spreadsheet/Excel file format) using our AI Extract tool
                which utilizes artificial intelligence for better results - at
                R10 for 5 page conversions (Optional).
              </li>
            </ul>
          </div>

          <div
            className="mobile7"
            style={{
              display: "inline-block",
              backgroundColor: "rgb(193 231 238 / 0%) ",
              boxShadow: boxShadow,
              borderRadius: 15,
            }}
          >
            {" "}
            <div style={{ paddingTop: 10, margin: 5 }}>
              <h2 style={{ textAlign: "center" }}>Business plan - R300 </h2>
            </div>
            <ul style={{ textAlign: "left" }}>
              Add your business for{" "}
              <span style={{ fontWeight: "bold" }}>R300 </span>(ZAR) per month.{" "}
              <li style={{ padding: 5 }}>
                This plan is suited for businesses that need invoicing, and also
                includes all features from the Accounting plan.
              </li>{" "}
              <li style={{ padding: 5, color: "#0011ff" }}>
                Generate and send invoices to clients monthly.
              </li>{" "}
              <li style={{ padding: 5, color: "#0011ff" }}>
                Save supplier and customer details for efficient invoice
                generation.
              </li>{" "}
              <li style={{ padding: 5, color: "#0011ff" }}>
                Save products and services for easier invoice generation.
              </li>{" "}
              <li style={{ padding: 5, color: "#0011ff" }}>
                Reconcile invoices issued with payments received.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Process monthly bank statement transactions.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Allocate multiple bank statement transactions together with
                simultaneous edits and link rules.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Analyse your businesses performance and profitability, with
                analytics and financial reports.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Generate financial statements, including profit and loss,
                balance sheet, cashflow, statement of changes in equity, and
                notes to the financial statements.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Add multiple users including your accountant or your staff
                members at no additional cost.
              </li>{" "}
              <li style={{ padding: 5 }}>
                Available online, anywhere, anytime.{" "}
              </li>
              <li style={{ padding: 5 }}>Process Vat calculations.</li>
              <li style={{ padding: 5 }}>
                Convert PDF bank statements to CSV (Comma separated values which
                is a spreadsheet/Excel file format) using our AI Extract tool
                which utilizes artificial intelligence for better results - at
                R10 for 5 page conversions (Optional).
              </li>
            </ul>
          </div>
        </div> */}
        <div>
          <img
            className="mobile16"
            style={{ margin: 2, padding: 10 }}
            alt="Visual"
            src={picture3}
          ></img>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default HomePageContent;
