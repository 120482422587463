import React from "react";

import { Link } from "react-router-dom";

import "./navigation.css";

import "./navigation.jsx";

export default function HomePage({
  stylingOnClickFeatures,
  stylingOnClickUsageInfo,
  stylingOnClickContact,
  stylingOnClickPricing,
  // url,
}) {
  // const wwidth = window.innerWidth;
  // console.log(wwidth);
  return (
    <div>
      {/* Welcome to filingstack */}
      {/* from bootstrap navbar with text */}
      {/* {wwidth > 991 ?  */}
      <div>
        <nav
          style={{
            backgroundColor: "rgb(255 255 255)",
            textAlign: "left",
            backgroundImage:
              //  url ||
              "linear-gradient(45deg, rgb(189, 243, 255), rgb(238, 255, 247))",
          }}
          className="navbar navbar-expand-lg navbar-light"
        >
          <h1 style={{ fontSize: 30 }}>FilingStack</h1>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-link active">
                <Link
                  style={{ fontWeight: "bold" }}
                  className="nav-link active"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li
                //  onClick={() => setstylingOnClickFeatures("MediumBlue")}
                className="nav-link active"
              >
                <Link
                  style={{ color: stylingOnClickFeatures, fontWeight: "bold" }}
                  className="nav-link"
                  to="/About"
                >
                  About
                </Link>
              </li>
              <li
                //  onClick={() => setstylingOnClickFeatures("MediumBlue")}
                className="nav-link active"
              >
                <Link
                  style={{ color: stylingOnClickUsageInfo, fontWeight: "bold" }}
                  className="nav-link"
                  to="/UsageInfo"
                >
                  Documentation
                </Link>
              </li>
              <li
                //  onClick={() => setstylingOnClickFeatures("MediumBlue")}
                className="nav-link active"
              >
                <Link
                  style={{ color: stylingOnClickPricing, fontWeight: "bold" }}
                  className="nav-link"
                  to="/Pricing"
                >
                  Pricing
                </Link>
              </li>
              <li
                //  onClick={() => setstylingOnClickFeatures("MediumBlue")}
                className="nav-link active"
              >
                <Link
                  style={{ color: stylingOnClickContact, fontWeight: "bold" }}
                  className="nav-link"
                  to="/ContactPage"
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            <span>
              <Link style={{ color: "blue", fontWeight: "bold" }} to="/Login">
                Login
              </Link>
              &nbsp;&nbsp;
              <Link style={{ color: "blue", fontWeight: "bold" }} to="/Signup">
                Sign up
              </Link>
            </span>
          </div>
        </nav>
      </div>
    </div>
  );
}
