import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
const RouteContext = React.createContext();

export function useForReRoute() {
  return useContext(RouteContext);
}

//main function
export function ReRoute({ children }) {
  const [ledgerIdTransaction, setledgerIdTransaction] = useState();
  const [AccountsIdTransaction, setAccountsIdTransaction] = useState();
  const [bankAccountID, setbankAccountID] = useState();
  const [checkedEntry, setcheckedEntry] = useState();
  const [journalsIdTransaction, setjournalsIdTransaction] = useState();

  const historyPageRoute = useHistory();
  const historyPageRouteToAccounts = useHistory();
  const historyPageRouteToJournals = useHistory();
  const historyPageRouteToPPE = useHistory();
  function reRouteTBToLedger(id) {
    // console.log(id);
    setledgerIdTransaction(id);
    historyPageRoute.push("/Ledger");
  }

  function reRouteToPPE() {
    historyPageRouteToPPE.push("/notes");
  }
  function reRouteLedgerToAccounts(
    id,
    journalOrAccounts,
    journalKey,
    bankAccountIDVal,
    checkedEntryVal
  ) {
    if (journalOrAccounts == "journaltrue") {
      setjournalsIdTransaction(journalKey);
      historyPageRouteToJournals.push("journals");
    } else {
      setAccountsIdTransaction(id);
      setbankAccountID(bankAccountIDVal);
      setcheckedEntry(checkedEntryVal);
      historyPageRouteToAccounts.push("/Accounts");
    }
  }

  function removeFilterAccountsPage() {
    setAccountsIdTransaction(false);
  }

  // value is an object that we pass as props to RouteContext.provider which provides theses object value props to all
  // its nested components, etc. the objects props are passed throughout our components.
  const value = {
    reRouteTBToLedger,
    reRouteToPPE,
    ledgerIdTransaction,
    setledgerIdTransaction,
    AccountsIdTransaction,
    bankAccountID,
    setbankAccountID,
    checkedEntry,
    setAccountsIdTransaction,
    reRouteLedgerToAccounts,
    journalsIdTransaction,
    setjournalsIdTransaction,
    removeFilterAccountsPage,
  };
  return (
    <RouteContext.Provider value={value}>{children}</RouteContext.Provider>
  );
}
