import React, { useState, useEffect } from "react";
import HomePage from "./HomePage";

import Footer from "./Footer";

const About = () => {
  const [stylingOnClickFeatures, setstylingOnClickFeatures] = useState();

  useEffect(() => {
    setstylingOnClickFeatures("MediumBlue");
  }, []);
  return (
    <div>
      <HomePage stylingOnClickFeatures={stylingOnClickFeatures} />
      <div
        style={{
          //backgroundImage: `url(${contactPicture})`,
          height: 200,
          marginBottom: 25,
          backgroundColor: "rgb(231, 252, 255)",
        }}
      >
        <h2 style={{ paddingTop: 70, color: "black" }}>
          About FilingStack Accounting{" "}
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "-20px",
        }}
      >
        <ul style={{ margin: 10, textAlign: "left", width: "100%" }}>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>What is Filingstack Accounting?</h6>
            Filingstack Accounting is a online accounting software application,
            accessible through a browser.
          </li>
          {/* <li
              style={{
                padding: 50,
                borderRadius: 15,
                margin: 10,
                backgroundColor: "#B5C3F8",
                listStyle: "none",
              }}
            >
              Multiple user access - more access, more users
            </li> */}

          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>What can you use Filingstack Accounting for?</h6>
            Filingstack Accounting can be used to store financial records and to
            generate financial accounting reports, including invoices, vat
            reports, ledgers, trial balances and financial statements.
            FilingStack offers advanced software tools that help convert PDF
            bank statements to CSV.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>Why Filingstack Accounting?</h6>
            Filingstack Accounting integrates your accounting records with
            financial statement generation, allowing for less complexity, high
            level efficiency, and a super user experience. Plus, you can
            generate beautiful invoices and accurate vat returns, all from 1
            application, with affordable pricing.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>Does Filingstack Accounting offer support to clients?</h6>
            Yes, we are available to assist. Visit the{" "}
            <a href="/ContactPage" target="_blank" rel="noreferrer">
              Contact Us
            </a>{" "}
            page for more details.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>How does Filingstack Accounting pricing work?</h6>
            Filingstack offers a affordable, month to month billing option,
            which bills clients monthly. Filingstack does not offer contracts
            and clients can opt out and cancel their subscription at any time.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>How do I cancel my subscription?</h6>
            Clients can cancel their subscription on the{" "}
            <a href="/Payment" target="_blank" rel="noreferrer">
              payments page
            </a>{" "}
            (clients must be logged in)
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>Does Filingstack Accounting offer a free trial period?</h6>
            Yes, all new clients get free access to a Demo company to review the
            Filingstack product. Signing up is free.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>Is Filingstack Accounting country specific?</h6>
            Filingstack Accounting welcomes international clients. The Payroll
            and vat reports are currently customized for South Africa. All
            billing is in South African Rands.
          </li>
          <li
            style={{
              padding: 50,
              borderRadius: 15,
              margin: 10,
              backgroundColor: "rgb(239 243 241 / 37%)",
              listStyle: "none",
            }}
          >
            <h6>Who is Filingstack managed by?</h6>
            Filingstack Accounting software was started by{" "}
            <a
              href="https://www.linkedin.com/in/zaahid-p-339435161/"
              target="_blank"
              rel="noreferrer"
            >
              Zaahid Patel
            </a>
            . Zaahid serves as a director of Filingstack. Zaahid is a graduate
            of{" "}
            <a
              href="https://www.mancosa.co.za/"
              target="_blank"
              rel="noreferrer"
            >
              Mancosa
            </a>
            .{" "}
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default About;
