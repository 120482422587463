import React, { useState, useEffect } from "react";
import HomePage from "./HomePage";
import contactPicture from "./gradienta-bKESVqfxass-unsplash.jpg";
//import contactPictureHomePage from "./pictures/pic14.jpg";

import { database, functions } from "./firebase";
import Footer from "./Footer";
function ContactPage({ OnContactPage = true }) {
  const [messageSent, setMessageSent] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [stylingOnClickContact, setstylingOnClickContact] = useState();
  useEffect(() => {
    let stop = false;
    if (stop) return;
    setstylingOnClickContact("MediumBlue");
    return () => (stop = true);
  }, []);
  const [subject, setSubject] = useState("Message from Contact Page");

  const handleMessage = (e) => {
    setMessageSent(true);
    e.preventDefault();
    let todayDate = new Date().toString();
    if (emailAddress && nameInput && message) {
      var timeout;
      //clear previous timeout function if any
      clearTimeout(timeout);
      database
        .ref("clientsMessages/")
        .push({
          username: nameInput,
          email: emailAddress,
          telephone: phoneNumber || "",
          callbackRequested: callbackRequested,
          tutorialRequest: tutorialRequest,

          message: message,
          Date: todayDate || "",
        })
        .then(async () => {
          //  functions.useEmulator("localhost", 5001);
          var EmailOnSupportRequestMessage = functions.httpsCallable(
            "EmailOnSupportRequestMessage"
          );

          await EmailOnSupportRequestMessage({
            id: nameInput + " " + emailAddress,

            emailSubject: `Message received - New message: ${
              subject || ""
            }. From: ${emailAddress}. Phone ${phoneNumber} Callback: ${callbackRequested} Tutorial: ${tutorialRequest} Date: ${todayDate}`,
            emailMessage:
              (message || "") +
              ` Message received - New message-Subject: ${
                subject || ""
              }. From: ${emailAddress}. Phone ${phoneNumber}`,
            emailHTML:
              ((message && message).replace(/\n/g, "<br />") || message || "") +
              `<br />Message received - New message-Subject: ${
                subject || ""
              }. From: ${emailAddress}. Phone ${phoneNumber}`,

            //can send data as an object
          })
            .then((result) => {
              // Read result of the Cloud Function.
              // var sanitizedMessage = result.data.text;
              // console.log(result.data);
              //  console.log("done");

              return result;
            })
            .catch((error) => {
              // Getting the Error details.
              var code = error.code;
              var message = error.message;
              var details = error.details;
              console.log(code, message, details);
              // ...
            });
        });

      setNameInput("");
      setEmailAddress("");
      setPhoneNumber("");
      setCallBackRequested("");
      setTutorialRequested("");
      setMessage("");

      timeout = setTimeout(() => {
        setMessageSent(false);
      }, 1500);
    }
    //  var messageFromClient = functions.httpsCallable("messageFromClient");

    //  await messageFromClient({
    //    //  id: uid,
    //    username: nameInput,
    //    email: emailAddress,
    //    telephone: phoneNumber,
    //    message: message,
    //    //can send data as an object
    //  })
    //    .then((result) => {
    //      // Read result of the Cloud Function.
    //      // var sanitizedMessage = result.data.text;
    //      // console.log(result.data);

    //      return result;
    //    })
    //    .catch((error) => {
    //      // Getting the Error details.
    //      var code = error.code;
    //      var message = error.message;
    //      var details = error.details;
    //      // ...
    //    });
  };
  const [callbackRequested, setCallBackRequested] = useState(false);
  const handleChange = (e) => {
    setNameInput(e.target.value);
  };
  const handleChange1 = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleChange2 = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChange3 = (e) => {
    setMessage(e.target.value);
  };
  const handleChange4 = (e) => {
    setCallBackRequested(e.target.checked);
  };
  const [tutorialRequest, setTutorialRequested] = useState(false);

  const handleChange5 = (e) => {
    setTutorialRequested(e.target.checked);
  };
  const [subscribeInfo, setsubscribeInfo] = useState("");
  const handleSubscribeEmail = (e) => {
    setsubscribeInfo(e.target.value);
  };
  const [subscribeAlert, setSubscribeAlert] = useState(false);
  const handleSubscribe = (e) => {
    if (subscribeInfo) {
      var timeout1;
      // record
      clearTimeout(timeout1);
      database.ref("subscribers/").push({
        email: subscribeInfo,
      });
      setsubscribeInfo("");
      setSubscribeAlert(true);
      timeout1 = setTimeout(() => {
        setSubscribeAlert(false);
      }, 1500);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {OnContactPage && (
        <HomePage stylingOnClickContact={stylingOnClickContact} />
      )}
      {OnContactPage && (
        <div
          style={{
            backgroundImage: `url(${contactPicture})`,
            height: 300,
            marginBottom: 25,
          }}
        >
          <h2
            style={{
              color: "white",
              // textAlign: "left",
              padding: 10,
              paddingTop: 50,
              fontFamily: "calibri",
            }}
          >
            Contact Us
          </h2>
          <h3
            style={{
              color: "white",
              //   textAlign: "left",
              padding: 10,
              paddingTop: 10,
              fontFamily: "calibri",
            }}
          >
            Email:{" "}
            <a style={{ color: "white" }} href="mailto:info@filingstack.com">
              info@filingstack.com
            </a>
          </h3>

          <h3
            style={{
              color: "white",
              // display: "inline-grid",
              //    textAlign: "left",
              //  paddingRight: 20,
              padding: 10,
              // marginLeft: 50,
              fontFamily: "calibri",
            }}
          >
            Tel:{" "}
            <a style={{ color: "white" }} href="tel:27631401165">
              +27 63 140 1165 (9 am to 5 pm SAST)
            </a>
          </h3>
        </div>
      )}
      {/* {!OnContactPage && (
        <div
          style={{
            backgroundImage: `url(${contactPictureHomePage})`,
            height: 300,
            marginBottom: 25,
            width: "100%",
            backgroundSize: "200%",
          }}
        >
      
          <h1
            style={{
              //color: "blue ",
              textAlign: "center",
              padding: 10,
              paddingTop: 100,

              fontFamily: "calibri",
            }}
          >
            Schedule a free demo session.
          </h1>
        </div>
      )} */}
      {
        <div>
          <h5
            style={{
              margin: 15,
              padding: 15,
              backgroundColor: "rgb(241 241 241)",
            }}
          >
            {" "}
            Contact Us.
          </h5>
        </div>
      }
      {
        <div
          style={{
            //backgroundImage: `url(${contactPictureHomePage})`,
            width: "100%",
            backgroundColor: "rgb(241 241 241)", //"#E0EEEA"
          }}
        >
          <form
            onSubmit={handleMessage}
            style={{
              display: "inline-grid",

              width: "80%",
            }}
          >
            <label>Name</label>
            <input
              placeholder="Name"
              required
              type="text"
              value={nameInput}
              style={{ margin: 20, padding: 20, width: "auto" }}
              onChange={handleChange}
            ></input>
            <label>Email</label>
            <input
              required
              placeholder="Email"
              type="email"
              value={emailAddress}
              onChange={handleChange1}
              style={{ margin: 20, padding: 20, width: "auto" }}
            ></input>
            <label>Phone (Optional)</label>
            <input
              placeholder="Telephone"
              type="tel"
              value={phoneNumber}
              onChange={handleChange2}
              style={{ margin: 20, padding: 20, width: "auto" }}
            ></input>

            <label>Message</label>
            <textarea
              value={message}
              onChange={handleChange3}
              placeholder="Your message"
              style={{ margin: 20, padding: 20, width: "auto" }}
            ></textarea>
            <label>
              <input
                type="checkbox"
                onChange={handleChange4}
                style={{ marginTop: 20, padding: 20, width: "auto" }}
              ></input>{" "}
              Request a call back
            </label>

            <label>
              <input
                type="checkbox"
                onChange={handleChange5}
                style={{ marginTop: 20, padding: 20, width: "auto" }}
              ></input>{" "}
              Request a online demo session{" "}
            </label>
            {tutorialRequest ? (
              <div>
                <iframe
                  title="Booking"
                  src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ392VYq1sQIs5C5auyiGnvyShLYZMY2dB1QNX2XwDzSjFM1jixkdLxQ5ISv8CVnbl4u4V3KQEv9?gv=true"
                  style={{ border: 0, width: "100%" }}
                  width="100%"
                  height="600"
                  frameborder="0"
                ></iframe>
              </div>
            ) : null}
            <br></br>
            <button
              style={{
                margin: 20,
                padding: 20,
                // borderRadius: 150,
                // width: "20%",

                backgroundColor: "black",
                // border: "none",
              }}
              //  disabled={messageSent}
              type="submit"
              className={
                (messageSent && "btn btn-success") || "btn btn-secondary"
              }
            >
              {(messageSent && "Message sent") || "Send message"}
            </button>
          </form>
        </div>
      }
      {/* <div>
        <div style={{ display: "inline-block" }}>Book a free live tutorial</div>

        <div style={{ display: "inline-block" }}>Request a call back</div>
      </div> */}
      <div
        style={{
          width: "100%",
          backgroundColor: "rgb(241 241 241)",
          height: 100,
          marginTop: 20,
        }}
      >
        <svg
          style={{ display: "inline-grid" }}
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          className="bi bi-envelope-check"
          viewBox="0 0 16 16"
        >
          <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
        </svg>
        <h5
          style={{ display: "inline-grid", textAlign: "center", padding: 10 }}
        >
          Subscribe
        </h5>
      </div>
      <div style={{ marginTop: 20 }}>
        {" "}
        <div
          style={{
            borderRadius: 15,
            backgroundColor: "",
            float: "left",
            width: "45%",
            padding: "10px",
          }}
        >
          <h4 style={{ marginTop: 10 }}>
            Subscribe for filingstack updates and promotions
          </h4>
        </div>
      </div>

      <div
        style={{
          borderRadius: 15,
          marginBottom: 20,
          float: "left",
          width: "45%",
          padding: "10px",
        }}
      >
        <div>
          <h2 style={{ float: "left" }}>
            Email{" "}
            <input
              style={{ width: "70%" }}
              type="email"
              value={subscribeInfo}
              onChange={handleSubscribeEmail}
            />
          </h2>
          <button
            style={{
              backgroundColor: "black",
              margin: 10,
              marginTop: 5,
            }}
            className="btn btn-secondary"
            onClick={handleSubscribe}
          >
            Subscribe
          </button>
        </div>
        {subscribeAlert && "Thanks for subscribing!"}
      </div>

      {OnContactPage && <Footer />}
    </div>
  );
}

export default ContactPage;
