import React from "react";
//import HomePage from "./HomePage";
import HomePageContent from "./HomePageContent";

import { FirebaseAuth } from "./context";

const RealHomePage = () => {
  return (
    <FirebaseAuth>
      <div>
        {/* <HomePage /> */}
        <HomePageContent />
      </div>
    </FirebaseAuth>
  );
};
export default RealHomePage;
