import React, { useState, useRef } from "react";
import { nanoid } from "nanoid";
import "../App";
import { Link, useHistory } from "react-router-dom";
import "./navigation.css";
import { auth, database, functions, analytics } from "./firebase";
import { FirebaseAuth, useForAuthorisation } from "./context";
//import HomePage from "./HomePage";

const Login = () => {
  const passwordRef = useRef();
  const emailRef = useRef();
  const { login, signInWithGoogle, location } = useForAuthorisation();

  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageGoogle, seterrorMessageGoogle] = useState();
  const [loadingAccount, setLoadingAccount] = useState(false);
  const historyPage = useHistory();
  const historyPage1 = useHistory();
  const historyPage2 = useHistory();
  const [idNanoid] = React.useState(nanoid);
  const [termsApproved, settermsApproved] = useState(true);
  const [ShowEmailLogin, setShowEmailLogin] = useState();
  var yearDate = new Date();
  // const handleTermsApproved = () => {
  //   settermsApproved(!termsApproved);
  // };

  const [TermsForGoogleSignUp, setTermsForGoogleSignUp] = useState(false);
  const handleGoogleTerms = () => {
    setTermsForGoogleSignUp(true);
    setShowEmailLogin(false);
  };
  const handleEmailAndPassword = () => {
    setShowEmailLogin(true);
    setTermsForGoogleSignUp(false);
    settermsApproved(true);
  };
  // console.log(auth.currentUser.uid);
  const handleSignInWithGoogle = async (e) => {
    e.preventDefault();

    await signInWithGoogle()
      .then(async (result) => {
        setLoadingAccount(true);
        database
          .ref("/ActiveUser/" + auth.currentUser.uid + "/SignedUp")
          .on("value", async (snapshot) => {
            //  const accounts =  snapshot.val();
            // console.log(accounts);
            let signedUp = await snapshot.val();
            if (signedUp === true) {
              let todayDate = new Date();
              // console.log(todayDate);
              setLoadingAccount(true);
              await database
                .ref("/UserSelections/" + auth.currentUser.uid)
                .update(
                  {
                    LastLoggedIn: todayDate,
                  },
                  (err) => {
                    if (err) {
                      console.log("error", err);
                    } else {
                      setLoadingAccount(false);
                    }
                  }
                );
              setLoadingAccount(false);
              historyPage2.push(location.state || "/AfterLoginPage");
              // console.log("============");
              // console.log(auth.currentUser.uid);
              // console.log(result.user.uid);

              return;
            }
            //   console.log("+++++");
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = credential.accessToken;
            // The signed-in user info.
            // var user = result.user;

            let uniqueID = idNanoid;

            //added recently - adds user after signup to user/Group with uid
            let uid = auth.currentUser.uid;
            // functions.useEmulator("localhost", 5001);
            var trialPeriod = functions.httpsCallable("trialPeriod");

            await trialPeriod({
              id: uid,
              //can send data as an object
            })
              .then((result) => {
                // Read result of the Cloud Function.
                // var sanitizedMessage = result.data.text;
                // console.log(result.data);
                // console.log("done");
                return result;
              })
              .catch((error) => {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                // ...
              });
            await database.ref("/PaymentsID/").child(uid).update({
              ID: uniqueID,
            });
            //   console.log(uid);
            // using .child(uid) twice to be consistent with other values in signUpAndAddUser component. although we ca have just
            // 1 level deep - two levels allow for multiple values to be stored if necessary, etc-but this can be removed.
            await database.ref("/User/").child(uid).child(uid).update({
              name: result.user.displayName,
              email: result.user.email,
              //uid below is owner uid - person who added users
              userId: uid,
              owner: true,
              member: false,
            });

            // await database.ref("/Payments/").child(uid).update({
            //   name: googleName,
            //   email: result.user.email,
            //   //uid below is owner uid - person who added users
            //   userId: uid,
            //   owner: true,
            //   member: false,
            //   ID: uniqueID,
            // });

            await database.ref("/Owner/").child(uid).child(uid).update({
              name: result.user.displayName,
              // owner/original email
              email: result.user.email,
              MAINuserId: uid,
              //uid below is owner uid - person who added users
              userId: uid,
              owner: true,
              member: false,
              address: "no",
              telephone: "",
            });
            // await database.ref("/User/").child(uid).push({
            //   clientCompanyAdded: "",
            //   afsType: "",
            //   Address: "",
            //   city: "",
            //   province: "",
            //   poBox:"",
            // });
            //
            const sameBusinessKey = database
              .ref("/User/" + uid + `/ClientCompanyAdded/`)
              .push().key;
            // console.log(sameBusinessKey);

            const companyAddRefdb = database.ref(
              "/User/" + uid + "/ClientCompanyAdded/" + sameBusinessKey
            );
            //posted twice to database- second post is to flatten data structure to enable less bandwith and download, when accessing
            // this posted data as an example -when downloading for the cover page. if we have only the first one then we'll have to
            // download all the whole tb - current and accounts , we also leave the first post , so company infomation may be ascertained
            // while browsing the tb accounts if necessary. it appears to be also used in tb current to have a push key on year roll forward
            // to post tb account infomation to.-may remove this-as may not be neccessary-check cover & settings component
            const companyAddRefdb1 = database.ref(
              "/BusinessDetails/" +
                uid +
                "/ClientCompanyAdded/" +
                sameBusinessKey
            );

            await companyAddRefdb1.update({
              companyNameRef: "Demo Company",
              financialsTypeRef: "IFRS SME",
              addressRef: "",
              cityRef: "City",
              provinceRef: "Province",
              poBoxRef: "PO BOX",
              yearEndDate: yearDate.getFullYear() + 1 + "-02-28",
              registrationNumber: yearDate.getFullYear() + 1 + "/0000/000",
              sameCompanyKey: sameBusinessKey,
              //notes is set to no initially, once user opens notes and clicks open note button, it can update to yes, and
              //post notes
              notes: "NO",
            });

            await companyAddRefdb.update({
              companyNameRef: "Demo Company",
              financialsTypeRef: "IFRS SME",
              addressRef: "",
              cityRef: "City",
              provinceRef: "Province",
              poBoxRef: "PO BOX",
              yearEndDate: yearDate.getFullYear() + 1 + "-02-28",
              registrationNumber: yearDate.getFullYear() + 1 + "/0000/000",

              sameCompanyKey: sameBusinessKey,
              //notes is set to no initially, once user opens notes and clicks open note button, it can update to yes, and
              //post notes
              notes: "NO",
            });

            await database
              .ref("/UserAccess/" + uid + "/ClientBusinesses/")
              .push({
                list: sameBusinessKey,
                companyNameRef: "Demo Company",
              });
            //
            analytics.logEvent("sign_up", {
              method: "Google",
            });
            //

            //
            //console.log("logged");
            ///

            setLoadingAccount(false);
            historyPage1.push(location.state || "/AfterLoginPage");
            // ...
          });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        var credential = error.credential;
        // The email of the user's account used.
        var email = error.email;
        // console.log(errorCode, errorMessage, email, credential);
        // The firebase.auth.AuthCredential type that was used.
        setLoadingAccount(false);
        seterrorMessageGoogle("Account Log in unsuccessful");
        // ...
      });
    //setLoadingAccount(false);
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");
      setLoadingAccount(true);
      // auth.auth().setPersistence(auth.Persistence.SESSION);
      await login(emailRef.current.value, passwordRef.current.value);

      //

      //checks if at least 1 company has been added
      // console.log(auth.currentUser.uid);
      let MainUserUID = auth.currentUser.uid;
      const urlDb = "/UserSelections/" + MainUserUID;
      const newUrlDb = database.ref(urlDb);
      await newUrlDb.once("value", (snapshot) => {
        setLoadingAccount(true);
        const accounts = snapshot.val();

        let newAccounts = [];

        newAccounts.push({ ...accounts });

        //  setNewData(newAccounts);

        let business =
          newAccounts.map((el1) => Object.keys(el1).length).toString() == 0
            ? "true"
            : "false";

        //console.log(business);
        localStorage.setItem("noSelection", business);
        setLoadingAccount(false);
      });
      let todayDate = new Date();
      // console.log(todayDate);
      setLoadingAccount(true);
      await database.ref("/UserSelections/" + MainUserUID).update(
        {
          LastLoggedIn: todayDate,
        },
        (err) => {
          if (err) {
            console.log("error", err);
          } else {
            setLoadingAccount(false);
          }
        }
      );

      // newUrlDb.off();
      // we can use useHistory from react router to push to the component in brackets.
      //added return recently - may have to remove
      setLoadingAccount(false);
      historyPage.push(location.state || "/AfterLoginPage");
    } catch {
      setLoadingAccount(false);
      setErrorMessage("Login unsuccessful");
    }
  };

  const showPassword = () => {
    if (passwordRef.current.type == "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };

  //
  return (
    <FirebaseAuth>
      <nav
        style={{
          backgroundColor: "lightskyblue",
          backgroundImage:
            "linear-gradient(45deg, rgb(189, 243, 255), rgb(238, 255, 247))",
        }}
        className="navbar navbar-expand-lg navbar-light bg-light"
      >
        <h1 style={{ fontSize: 30 }}>FilingStack</h1>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-link active">
              <Link className="nav-link active" to="/">
                Home
              </Link>
            </li>
            <li className="nav-link active">
              <Link className="nav-link" to="/About">
                About
              </Link>
            </li>
            <li className="nav-link active">
              <Link className="nav-link" to="/UsageInfo">
                Documentation
              </Link>
            </li>
            <li className="nav-link active">
              <Link className="nav-link" to="/Pricing">
                Pricing
              </Link>
            </li>
            <li className="nav-link active">
              <Link className="nav-link" to="/ContactPage">
                Contact Us
              </Link>
            </li>
          </ul>

          <span>
            {/* <Link style={{ color: "blue" }} to="/Login">
                  Login
                </Link> */}
            &nbsp;&nbsp;
            <Link style={{ color: "blue" }} to="/Signup">
              Sign up
            </Link>
          </span>
        </div>
      </nav>

      <h5 style={{ textAlign: "center", padding: 5, margin: 5 }}>
        Enter your details to Login.
      </h5>
      {loadingAccount ? (
        <div
          // style={{ marginRight: 115, marginTop: 10 }}
          className="spinner-border text-primary"
          role="status"
        ></div>
      ) : null}
      <div style={{ marginTop: 50 }}>
        <button
          // style={{ marginRight: 20 }}
          className="btn btn-primary"
          onClick={handleGoogleTerms}
        >
          Login with a Google Account
        </button>
        <button
          className="btn btn-primary mobile4"
          onClick={handleEmailAndPassword}
        >
          Login with Email/Password
        </button>
      </div>
      {ShowEmailLogin && (
        <div className="loginStyle">
          <form onSubmit={handleLoginSubmit}>
            {errorMessage && (
              <div className="alert alert-warning" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="col-12">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                ref={emailRef}
              />
              {/* <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            </div>
            <div className="col-12">
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                type="password"
                //style={{ marginLeft: 50, marginRight: 50 }}
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                ref={passwordRef}
              />
              <div style={{ display: "inline" }}>
                <input
                  style={{ display: "inline", width: "auto" }}
                  id="showpass"
                  type="checkbox"
                  onClick={showPassword}
                />{" "}
                <label htmlFor="showpass"> Show Password</label>
                <Link
                  style={{ display: "inline", marginRight: -15 }}
                  className="nav-link"
                  to="/ResetPasscode"
                >
                  Reset Password
                </Link>
              </div>
              {/* <Link
              style={{ marginRight: -15 }}
              className="nav-link"
              to="/TermsAndConditions"
            >
              Terms And Conditions
            </Link> */}
            </div>

            <button
              disabled={loadingAccount}
              type="submit"
              className="btn btn-primary"
            >
              Login
            </button>
          </form>
        </div>
      )}
      {TermsForGoogleSignUp && (
        <form type="submit">
          <label style={{ paddingTop: 20 }} htmlFor="showterms">
            <input
              style={{ padding: 10, width: "auto" }}
              id="showterms"
              type="checkbox"
              //  onClick={handleTermsApproved}
              checked
            />{" "}
            <Link
              target="_blank"
              rel="noreferrer"
              style={{ display: "inline", marginRight: -15 }}
              className="nav-link"
              to="/TermsAndConditions"
            >
              Agree to Terms And Conditions and Privacy policy to continue
            </Link>
            {/* <Link
                  style={{ display: "inline", marginRight: -15 }}
                  className="nav-link"
                  to="/TermsAndConditions"
                >
                  
                </Link> */}
          </label>
          <div>
            <button
              // disabled={termsApproved}
              className="btn btn-success"
              onClick={handleSignInWithGoogle}
            >
              Login
            </button>
          </div>
          {errorMessageGoogle && (
            <div className="alert alert-warning" role="alert">
              {errorMessageGoogle}
            </div>
          )}
        </form>
      )}
    </FirebaseAuth>
  );
};
export default Login;
