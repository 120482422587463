import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import HomePage from "./HomePage";
import "./tbcurrent.css";

function Pricing() {
  const [stylingOnClickPricing, setstylingOnClickPricing] = useState();
  useEffect(() => {
    setstylingOnClickPricing("MediumBlue");
  }, []);
  //

  // const [companiesAdded, setCompaniesAdded] = useState(1);
  //  const [price, setPrice] = useState(25);
  // const [payrollAdded, setPayrollAdded] = useState(1);
  // const [payrollprice, setPayrollPrice] = useState(20);
  // const [TransactionsAdded, setTransactionsAdded] = useState(3000);
  // const [TransactionsAddedPrice, setTransactionsAddedPrice] = useState(0);
  // const [employeesAdded, setemployeesAdded] = useState(10);

  // const [employeesAddedPrice, setemployeesAddedPrice] = useState(20);
  //  const [notesPagesAddedPrice, setnotesPagesAddedPrice] = useState(0);
  // const [notesPagesAdded, setnotesPagesAdded] = useState(5);
  // const [changes, setChanges] = useState("changed");

  // const [invoicesAdded, setInvoicesAdded] = useState(50);
  // const [invoicesAddedPrice, setInvoicesAddedPrice] = useState("");
  // const handleAccountingAdded = (e) => {
  //   //divide transaction limit by companies added
  //   setCompaniesAdded(e.target.value);
  //   if (e.target.value < 1) {
  //     setTransactionsAddedPrice(
  //       Number(TransactionsAdded) > 250 || Number(e.target.value) < 1
  //         ? Number(TransactionsAdded) * 0.03
  //         : 0
  //     );
  //     setPrice(0);
  //   } else {
  //     setPrice(Number(e.target.value) >= 1 ? Number(e.target.value) * 25 : 0);
  //     setTransactionsAddedPrice(
  //       Number(TransactionsAdded) > 250 ? Number(TransactionsAdded) * 0.03 : 0
  //     );
  //   }
  //   // setChanges("changed");
  // };
  //   const handlePayrollAdded = (e) => {
  //     setPayrollAdded(e.target.value);
  //     setPayrollPrice(Number(e.target.value) * 5);
  //     setChanges("changed");
  //   };
  // const handleTransactionsAdded = (e) => {
  //   setTransactionsAdded(e.target.value);
  //   setTransactionsAddedPrice(
  //     Number(e.target.value) > 3000 ? (Number(e.target.value) * 0.03) / 12 : 0
  //   );
  //   //  setChanges("changed");
  // };
  // const [PDFPagesAdded, setPDFPagesAdded] = useState(0);
  //const [PDFPagesAddedPrice, setPDFPagesAddedPrice] = useState(0);
  // const handlePDFPagesAdded = (e) => {
  //   setPDFPagesAdded(e.target.value);
  //   setPDFPagesAddedPrice(Number(e.target.value) * 2);
  //   //  setChanges("changed");
  // };
  // const handleNotesPagesAdded = (e) => {
  //   setnotesPagesAdded(e.target.value);
  //   setnotesPagesAddedPrice(
  //     Number(e.target.value) > 5 ? Number(e.target.value) * 0.3 : 0
  //   );
  //   // setChanges("changed");
  // };
  // const handleEmployeesAdded = (e) => {
  //   setemployeesAdded(e.target.value);
  //   setemployeesAddedPrice(Number(e.target.value) * 2);
  //   // setChanges("changed");
  // };

  // const handleInvoicesAdded = (e) => {
  //   setInvoicesAdded(e.target.value);
  //   // console.log((Number(e.target.value) - 50) * 0.4);
  //   setInvoicesAddedPrice(
  //     Number(e.target.value) > 50 ? (Number(e.target.value) - 50) * 0.4 : 0
  //   );
  //   // setChanges("changed");
  // };
  // const [planSelected, setPlanSelected] = useState("Accounting");
  // const handlePlan = (e) => {

  //   if (e.target.value === "Business") {
  //     setPlanSelected("Business");
  //     setPrice(300);
  //   } else if (e.target.value === "Accounting") {
  //     setPlanSelected("Accounting");
  //     setPrice(50);
  //   } else if (e.target.value === "Free") {
  //     setPlanSelected("Free plan");
  //     setPrice(0);
  //   }
  // };
  const [open, setOpen] = useState({});

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };
  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const [switchV, setSwitchV] = useState(true);
  const handleSwitch = (e) => {
    setSwitchV(!switchV);
  };
  function AnnualOrMonthly(el) {
    let monthlyOrAnnualValue = switchV ? 12 : 1;
    return `R${el * monthlyOrAnnualValue}`;
  }
  <div className="custom-control custom-switch">
    <input
      onChange={handleSwitch}
      type="checkbox"
      className="custom-control-input"
      id="customSwitch1"
      checked={switchV}
    />
    <label className="custom-control-label" htmlFor="customSwitch1">
      {switchV ? `Annual Pricing` : `Annual Pricing`}
    </label>
  </div>;

  function QuestionAnswer(elText1, elText2, elID) {
    return (
      <div className="pricingTableCSS">
        <h3 style={{ textAlign: "left", margin: 20, padding: 10 }}>
          <span> {elText1}</span>

          {open[elID] === true ? (
            <>
              {" "}
              <svg
                onClick={() => handleClose(elID)}
                style={{ cursor: "pointer" }}
                id={elID}
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                fill="blue"
                class="bi bi-dash-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                />
              </svg>{" "}
            </>
          ) : (
            <svg
              onClick={() => handleOpen(elID)}
              id={elID}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              fill="blue"
              class="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
            </svg>
          )}
        </h3>
        {open[elID] === true ? (
          <>
            <p
              style={{
                textAlign: "left",
                margin: 20,
                padding: 10,
                fontSize: 18,
              }}
              id={elID}
            >
              {elText2}
            </p>
          </>
        ) : null}{" "}
        <hr></hr>
      </div>
    );
  }

  function PriceLineItem(el, check1, check2, check3) {
    return (
      <tr>
        <td style={{ textAlign: "left", fontSize: 16 }}>{el}</td>
        <td>
          {check1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="blue"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          ) : null}
        </td>
        <td>
          {check2 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="blue"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          ) : null}
        </td>
        <td>
          {check3 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="blue"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          ) : null}
        </td>
      </tr>
    );
  }

  const mediaMatch = window.matchMedia("(max-width: 948px)");

  const [matches, setMatches] = useState(mediaMatch.matches);
  let boxShadow = "rgb(86 204 217 / 45%) 0px 1px 2px 2px"; //"rgb(191 211 223 / 45%) 0px 1px 2px 2px"; // previous 'rgb(79 189 255 / 45%) 0px 1px 2px 2px'

  //
  return (
    <div>
      <HomePage stylingOnClickPricing={stylingOnClickPricing} />
      <div
        style={{
          //backgroundImage: `url(${contactPicture})`,
          height: 200,
          marginBottom: 25,
          backgroundColor: "rgb(231, 252, 255)",
        }}
      >
        <h2 style={{ paddingTop: 70, color: "black" }}>Pricing Overview</h2>
      </div>
      <div>
        <p
          style={{
            marginLeft: 100,
            marginRight: 100,
            padding: 15,
            textAlign: "center",
          }}
        >
          FilingStack offers pricing per entity, monthly or annually. View our
          plans, pricing and features below.
        </p>
      </div>

      <div className="custom-control custom-switch">
        <input
          onChange={handleSwitch}
          type="checkbox"
          className="custom-control-input"
          id="customSwitch1"
          checked={switchV}
        />
        <label className="custom-control-label" htmlFor="customSwitch1">
          {switchV ? `Annual Pricing` : `Annual Pricing`}
        </label>
      </div>
      <div
        className="mobile6"
        style={{ margin: 10, width: "90%", marginLeft: "5%" }}
      >
        <table className="pricingTableCSS" style={{ borderWidth: "0px" }}>
          <tbody>
            <tr>
              <th>Plans</th>
              <th>Accounting</th>
              <th>Invoice starter</th>
              <th>Invoice plus</th>
            </tr>
            <tr>
              <th>{switchV ? "Annual pricing" : "Monthly pricing"}</th>
              <th>{AnnualOrMonthly(35)}</th>
              <th>{AnnualOrMonthly(49)}</th>
              <th>{AnnualOrMonthly(99)}</th>
            </tr>

            {PriceLineItem(
              "Generate ledger and trial balance reports.",
              true,
              true,
              true
            )}

            {PriceLineItem("Generate financial statements.", true, true, true)}
            {PriceLineItem("Access analytical data graphs.", true, true, true)}
            {PriceLineItem("Add multiple users.", true, true, true)}
            {PriceLineItem(
              "Batch allocate and Auto allocate.",
              true,
              true,
              true
            )}
            {PriceLineItem("Generate Vat calculations.", true, true, true)}
            {/* {PriceLineItem(
              "Convert PDF bank statement conversions to CSV at R2 a page (optional).",
              true,
              true,
              true
            )} */}
            {PriceLineItem(
              "Generate and send up to 15 invoices to clients monthly.",
              false,
              true,
              true
            )}
            {PriceLineItem(
              "Save product and services for invoices.",
              false,
              true,
              true
            )}
            {PriceLineItem(
              "Save customer and supplier details for invoices.",
              false,
              true,
              true
            )}
            {PriceLineItem(
              "Add a Pay Now button for invoices.",
              false,
              true,
              true
            )}

            {PriceLineItem(
              "Generate and send more than 15 invoices to clients monthly.",
              false,
              false,
              true
            )}
          </tbody>
        </table>
      </div>

      <div>
        <h1>FAQ</h1>
      </div>
      {QuestionAnswer(
        "Is there a plan available for bulk accounting processing?",
        `Yes, the Accounting plan is ideal for accounting processing.
      The Accounting plan
        is designed for
         accountants and others who need to generate a
         ledger, trial balance, Vat reports and financial statements.
        The Accounting plan is R300 annually or R25 monthly, per business added.`,
        1
      )}
      {QuestionAnswer(
        "Which Plan is for businesses?",
        `The Invoice Starter plan is ideal for small businesses
          that need to process their accounting records, and who also need to
          send invoices to their clients. The Invoice Plus plan is ideal for
          businesses that need to process their accounting records and also need
          to send many invoices to their clients.`,
        2
      )}
      {QuestionAnswer(
        "Can the software convert PDF bank statements to CSV?",
        `Yes, the software has options to convert PDF bank statements to CSV at R2 per page uploaded.`,
        3
      )}
      {QuestionAnswer(
        "How will I be billed?",
        `Billing takes place monthly or annually and is processed securely by Payfast.`,
        4
      )}
      {QuestionAnswer(
        "Can I cancel my subscription?",
        `Yes, you may cancel your subscription at any time. 
        The Payments page has options to subscribe and unsubscribe.
        If you cancel your subscription, it'll take effect from the following month (the next billing period).`,
        5
      )}

      {/* <div
        className="mobile14"
        //style={{ margin: 10, width: "90%", marginLeft: "5%" }}
      >
        <div
          className="mobile9"
          style={{
            display: "inline-block",
            backgroundColor: "rgb(193 231 238 / 0%) ",
            boxShadow: boxShadow,

            borderRadius: 15,
            //   width: "50%",
          }}
        >
          <div style={{ paddingTop: 10, margin: 5 }}>
            <h2 style={{ textAlign: "center" }}>Free plan</h2>
          </div>
          <ul style={{ textAlign: "left" }}>
            Add one business and get started for free.
            <li style={{ padding: 5 }}>
              Generate and send up to 15 invoices to clients monthly.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Save supplier and customer details for efficient invoice
              generation.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Save products and services for easier invoice generation.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Reconcile invoices issued with payments received.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Process up to 100 monthly bank statement transactions.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Allocate multiple bank statement transactions together with
              simultaneous edits and link rules.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Analyse your businesses performance and profitability, with
              analytics and financial reports.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Generate financial statements, including profit and loss, balance
              sheet, cashflow, statement of changes in equity, and notes to the
              financial statements.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Add multiple users including your accountant or your staff members
              at no additional cost.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Available online, anywhere, anytime.{" "}
            </li>
            <li style={{ padding: 5 }}>Process Vat calculations.</li>
            <li style={{ padding: 5 }}>
              Convert PDF bank statements to CSV (Comma separated values which
              is a spreadsheet/Excel file format) using our AI Extract tool
              which utilizes artificial intelligence for better results - at R10
              for 5 page conversions (Optional).
            </li>
          </ul>
        </div>

        <div
          className="mobile7"
          style={{
            display: "inline-block",
            backgroundColor: "rgb(193 231 238 / 0%) ",
            boxShadow: boxShadow,
            borderRadius: 15,
          }}
        >
          {" "}
          <div style={{ paddingTop: 10, margin: 5 }}>
            <h2 style={{ textAlign: "center" }}>
              Accountants plan - from R25{" "}
            </h2>
          </div>
          <ul style={{ textAlign: "left" }}>
            Add an entity from just{" "}
            <span style={{ fontWeight: "bold" }}>R25 </span>(ZAR) per month.{" "}
            <li style={{ padding: 5 }}>
              This plan is ideal for accounting practices and businesses.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Generate and send invoices to clients monthly.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Save supplier and customer details for efficient invoice
              generation.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Save products and services for easier invoice generation.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Reconcile invoices issued with payments received.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Process monthly bank statement transactions.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Allocate multiple bank statement transactions together with
              simultaneous edits and link rules.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Analyse your businesses performance and profitability, with
              analytics and financial reports.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Generate financial statements, including profit and loss, balance
              sheet, cashflow, statement of changes in equity, and notes to the
              financial statements.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Add multiple users including your accountant or your staff members
              at no additional cost.
            </li>{" "}
            <li style={{ padding: 5 }}>
              Available online, anywhere, anytime.{" "}
            </li>
            <li style={{ padding: 5 }}>Process Vat calculations.</li>
            <li style={{ padding: 5 }}>
              Convert PDF bank statements to CSV (Comma separated values which
              is a spreadsheet/Excel file format) using our AI Extract tool
              which utilizes artificial intelligence for better results - at R10
              for 5 page conversions (Optional).
            </li>
          </ul>
        </div>
      </div> */}
      {/* <div>
        <h6
          style={{
            marginLeft: "10%",

            marginRight: 100,
            paddingLeft: 15,
            textAlign: "left",
            width: "70%",
          }}
        >
          Understanding FilingStack Accounting pricing;
        </h6>
        <p
          style={{
            marginLeft: "10%",
            marginRight: 100,
            paddingLeft: 15,
            textAlign: "left",
            width: "70%",
          }}
        >
          FilingStack offers a monthly billing payment method. You won't be
          billed more than the amount you approve. Pricing is based on the usage
          limits set by you. This allows clients to benefit through usage based
          proportionate pricing.
          <br></br>
          <br></br>
          The option "How many bank statement transactions does your business
          process annually?" below should be set to the maximum amount of bank
          statement transactions you expect to upload annually on to the
          software for the current business, eg; 1000 monthly * 12 months =
          20000 transactions. This limit can be changed at any time.
          <br></br>
          <br></br>
          The option "How many PDF pages do want to convert to CSV using AI
          Extract?" below should be set to the amount of PDF bank statement
          pages you'd like to convert to CSV monthly using the AI Extract tool
          on the Convert PDF page. eg; 5 pages. These credits added don't expire
          and can be used by any business added. This limit can be changed at
          any time.
          <br></br>
          <br></br>
          The option "How many invoices does your business issue monthly?" below
          should be set to the maximum amount of invoices you expect to issue to
          clients monthly for the current business eg; 100 invoices. This limit
          can be changed at any time.
        </p>
      </div>
      <div style={{ padding: 5 }}>
        <h5>Pricing calculator</h5>
        <table
          className="pricingTableCSS"
          style={{
            // margin: 60,
            // marginLeft: matches ? 0 : 140,
            //  backgroundColor: "lightblue",
            // width: "80%",
            borderRadius: 20,
          }}
        >
          <tbody>
            <tr style={{ marginTop: 100 }}>
              <td
                style={{ fontWeight: "bold", width: "100", textAlign: "left" }}
              >
                Options
              </td>
              <td
                style={{ fontWeight: "bold", width: "300", textAlign: "left" }}
              >
                Total
              </td>
            </tr>
            {/* <tr>
              <td style={{ textAlign: "left" }}>
                How many businesses would you like to add?
              </td>

              <td>
                <input
                  type="number"
                  onChange={handleAccountingAdded}
                  value={companiesAdded}
                ></input>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Businesses Added</td>
              <td style={{ textAlign: "left" }}>One</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>
                How many bank statement transactions does your business process
                annually?
              </td>
              <td>
                {" "}
                <input
                  type="number"
                  onChange={handleTransactionsAdded}
                  value={TransactionsAdded}
                ></input>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>
                Bank statement PDF pages to convert to CSV using AI Extract -
                credits don't expire.
              </td>
              <td>
                {" "}
                <input
                  type="number"
                  onChange={handlePDFPagesAdded}
                  value={PDFPagesAdded}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Number of Notes pages</td>
              <td>
                {" "}
                <input
                  type="number"
                  onChange={handleNotesPagesAdded}
                  value={notesPagesAdded}
                ></input>
              </td>
            </tr>  

            <tr>
              <td style={{ textAlign: "left" }}>
                How many invoices does your business issue monthly?
              </td>

              <td>
                <input
                  type="number"
                  onChange={handleInvoicesAdded}
                  value={invoicesAdded}
                  // class="form-check-input"
                  id="invoicesAdded"
                ></input>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Total Price (ZAR)</td>

              <td style={{ textAlign: "left" }}>{`R${
                //   employeesAddedPrice +
                (
                  price +
                  TransactionsAddedPrice +
                  //  notesPagesAddedPrice +
                  PDFPagesAddedPrice +
                  Number(invoicesAddedPrice)
                ).toFixed(2)
              } `}</td>
            </tr>
          </tbody>
        </table> </div>*/}
      {/* <div>
        <h6>
          Additional detailed pricing information can be found on the{" "}
          <a
            style={{ fontWeight: "bold" }}
            href="./Payment"
            target="_blank"
            rel="noreferrer"
          >
            Payments
          </a>{" "}
          page, after logging in.
        </h6>
      </div> */}

      <div>
        {" "}
        <Footer />
      </div>
    </div>
  );
}

export default Pricing;
