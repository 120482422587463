import React from "react";
import { Link } from "react-router-dom";
import "./home.css";
import image from "./pictures/facebook.png";
import imageLinkedIn from "./pictures/linkedIn.png";
import XLogo from "./pictures/xLogo.png";
function Footer() {
  return (
    <ul
      className="mobile2"
      style={{
        //  backgroundColor: "rgb(247 247 247)",
        marginTop: 20,
        width: "100%",
        justifyContent: "center",
        // textAlign: "left",
        // display: "inline-flex",
        listStyle: "none",
        //
      }}
    >
      <li
        className="mobile2"
        style={{
          textAlign: "center",
          //display: "inline-flex",
        }}
        // marginTop: 230,
      >
        <h6 style={{ paddingLeft: "20px", textAlign: "left", paddingTop: 10 }}>
          FilingStack{" "}
        </h6>{" "}
      </li>
      <li className="mobile2" style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <Link
            // target="_blank"
            // rel="noreferrer"
            //  className="nav-link"
            to="/Signup"
          >
            Sign up
          </Link>
        </h6>
      </li>
      <li className="mobile2" style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <a href="/UsageInfo" target="_blank" rel="noreferrer">
            Documentation
          </a>
        </h6>
      </li>
      <li className="mobile2" style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <Link
            target="_blank"
            rel="noreferrer"
            //  className="nav-link"
            to="/TermsAndConditions"
          >
            Terms And Conditions
          </Link>
        </h6>
      </li>{" "}
      <li style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtube.com/@filingstackaccounting4825?feature=shared"
          >
            YouTube
          </a>
        </h6>
        {/* <li>Phone : +27 681 7790 78</li> */}
      </li>
      <li
        className="mobile2"
        style={{
          // backgroundColor: "rgb(247 247 247)",
          // float: "left",
          // width: "33%",
          paddingLeft: "20px",
          // display: "inline-flex",
          paddingTop: 10,
          //  marginTop: 20,
        }}
      >
        <h6>
          {/* <li> Social Pages:</li> */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/FilingStack-Accounting-110181234867936/"
          >
            <img
              style={{ width: "30px", height: "29px", align: "left" }}
              alt="facebook"
              src={image}
            />
          </a>
        </h6>
      </li>{" "}
      <li style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://za.linkedin.com/company/filingstack-accounting?trk=public_profile_topcard-current-company/"
          >
            <img
              style={{ width: "30px", height: "29px", align: "left" }}
              src={imageLinkedIn}
              alt="LinkedIn"
            />
          </a>
        </h6>
      </li>
      <li style={{ paddingLeft: "20px", paddingTop: 10 }}>
        <h6>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://x.com/FilingStack?s=20"
          >
            <img
              style={{ width: "30px", height: "29px", align: "left" }}
              src={XLogo}
              alt="X Logo"
            />
          </a>
        </h6>
      </li>
    </ul>
  );
}

export default Footer;
