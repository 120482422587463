import React from "react";
import { Link } from "react-router-dom";
//import Navigation from "./navigation";
const NotesNav = ({
  notesContents,
  coverColor,
  contentsColor,
  ReportsColor,
  ppeColor,
}) => {
  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(45deg, rgb(231, 252, 255), transparent)",
      }}
    >
      {/* <Navigation stylingOnClickInvoices="MediumBlue" /> */}
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <Link
                style={{ color: notesContents }}
                className="nav-link active"
                to="/NotesMenu"
              >
                <li>Notes Added</li>
              </Link>
              <Link
                style={{ color: coverColor }}
                className="nav-link active"
                to="/cover"
              >
                <li> Cover page </li>
              </Link>
              <Link
                style={{ color: contentsColor }}
                className="nav-link active"
                to="/contents.jsx"
              >
                <li className="nav-item"> AFS Contents </li>
              </Link>
              <Link
                style={{ color: ReportsColor }}
                className="nav-link active"
                to="/report.jsx"
              >
                <li className="nav-item"> Reports </li>
              </Link>{" "}
              <Link
                style={{ color: ppeColor }}
                className="nav-link active"
                to="/notes"
              >
                <li> PPE Notes </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NotesNav;
